import { SET_GRADE_TH } from "../actionTypes";

const DEFAULT_STATE = { grade: 50 };

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case SET_GRADE_TH:
      return { grade: action.grade };
    default:
      return state;
  }
};
