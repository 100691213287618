import React, { Component } from "react";
import { connect } from "react-redux";
import * as d3 from "d3";
import { setValue } from "../store/actions/skills";
import { withTranslation } from "react-i18next";

class Skills extends Component {
  constructor(props) {
    super(props);

    this.width = 600;
    this.height = 600;
    this.subAreasPerNum = 3;

    this.createPieChart = this.createPieChart.bind(this);
    this.drawGraph = this.drawGraph.bind(this);

    this.colorScaleAreas = d3
      .scaleOrdinal()
      .domain(this.props.areasDefault.map((_, i) => i))
      .range([
        "#10253f",
        "#1e1c11",
        "#403152",
        "#665500",
        "#984807",
        "#254061",
        "#632523",
        "#025716",
      ]);

    this.colorScaleSubAreas = d3
      .scaleOrdinal()
      .domain(this.props.subAreasDefault.map((_, i) => i))
      .range([
        "#17375e",
        "#1f497d",
        "#558ed5",
        "#4a452a",
        "#948a54",
        "#c4bd97",
        "#604a7b",
        "#8064a2",
        "#b3a2c7",
        "#806a00",
        "#b39500",
        "#aaaf45",
        "#e46c0a",
        "#f79646",
        "#fac090",
        "#376092",
        "#4f81bd",
        "#95b3d7",
        "#953735",
        "#c0504d",
        "#d99694",
        "#39804a",
        "#68995b",
        "#58CC6e",
      ]);
  }

  static defaultProps = {
    areasDefault: [
      { class: "culture-leadership", name: "skills.title1", abbr: "CULTURE" },
      { class: "team-building", name: "skills.title2", abbr: "TEAM" },
      { class: "financials", name: "skills.title3", abbr: "FINANCE" },
      { class: "product-development", name: "skills.title4", abbr: "PRODUCT" },
      { class: "selling", name: "skills.title5", abbr: "SELLING" },
      { class: "marketing-promotion", name: "skills.title6", abbr: "PROMO" },
      { class: "industry-experience", name: "skills.title7", abbr: "INDUSTRY" },
      { class: "raising-capital", name: "skills.title8", abbr: "CAPITAL" },
    ],

    subAreasDefault: [
      {
        class: "creating-VMVG",
        name: "skills.subTitle1",
        subQuestions: [
          { inputValue: [1, 2, 3, 4], ask: "skills.subTitle1question1" },
          { inputValue: [1, 2, 3, 4], ask: "skills.subTitle1question2" },
        ],
      },
      {
        class: "implementing-VMVG",
        name: "skills.subTitle2",
        subQuestions: [
          { inputValue: [1, 2, 3, 4], ask: "skills.subTitle2question1" },
          { inputValue: [1, 2, 3, 4], ask: "skills.subTitle2question2" },
        ],
      },
      {
        class: "hiring-VMVG",
        name: "skills.subTitle3",
        subQuestions: [
          { inputValue: [1, 2, 3, 4], ask: "skills.subTitle3question1" },
          { inputValue: [1, 2, 3, 4], ask: "skills.subTitle3question2" },
        ],
      },
      {
        class: "strategy-TB",
        name: "skills.subTitle4",
        subQuestions: [
          { inputValue: [1, 2, 3, 4], ask: "skills.subTitle4question1" },
          { inputValue: [1, 2, 3, 4], ask: "skills.subTitle4question2" },
        ],
      },
      {
        class: "creating-TB",
        name: "skills.subTitle5",
        subQuestions: [
          { inputValue: [1, 2, 3, 4], ask: "skills.subTitle5question1" },
          { inputValue: [1, 2, 3, 4], ask: "skills.subTitle5question2" },
        ],
      },
      {
        class: "operating",
        name: "skills.subTitle6",
        subQuestions: [
          { inputValue: [1, 2, 3, 4], ask: "skills.subTitle6question1" },
          { inputValue: [1, 2, 3, 4], ask: "skills.subTitle6question2" },
        ],
      },
      {
        class: "creating-F",
        name: "skills.subTitle7",
        subQuestions: [
          { inputValue: [1, 2, 3, 4], ask: "skills.subTitle7question1" },
          { inputValue: [1, 2, 3, 4], ask: "skills.subTitle7question2" },
        ],
      },
      {
        class: "reading",
        name: "skills.subTitle8",
        subQuestions: [
          { inputValue: [1, 2, 3, 4], ask: "skills.subTitle8question1" },
          { inputValue: [1, 2, 3, 4], ask: "skills.subTitle8question2" },
        ],
      },
      {
        class: "following",
        name: "skills.subTitle9",
        subQuestions: [
          { inputValue: [1, 2, 3, 4], ask: "skills.subTitle9question1" },
          { inputValue: [1, 2, 3, 4], ask: "skills.subTitle9question2" },
        ],
      },
      {
        class: "creation-PD",
        name: "skills.subTitle10",
        subQuestions: [
          { inputValue: [1, 2, 3, 4], ask: "skills.subTitle10question1" },
          { inputValue: [1, 2, 3, 4], ask: "skills.subTitle10question2" },
        ],
      },
      {
        class: "technical",
        name: "skills.subTitle11",
        subQuestions: [
          { inputValue: [1, 2, 3, 4], ask: "skills.subTitle11question1" },
          { inputValue: [1, 2, 3, 4], ask: "skills.subTitle11question2" },
        ],
      },
      {
        class: "commercializing",
        name: "skills.subTitle12",
        subQuestions: [
          { inputValue: [1, 2, 3, 4], ask: "skills.subTitle12question1" },
          { inputValue: [1, 2, 3, 4], ask: "skills.subTitle12question2" },
        ],
      },
      {
        class: "phone",
        name: "skills.subTitle13",
        subQuestions: [
          { inputValue: [1, 2, 3, 4], ask: "skills.subTitle13question1" },
          { inputValue: [1, 2, 3, 4], ask: "skills.subTitle13question2" },
        ],
      },
      {
        class: "discipline",
        name: "skills.subTitle14",
        subQuestions: [
          { inputValue: [1, 2, 3, 4], ask: "skills.subTitle14question1" },
          { inputValue: [1, 2, 3, 4], ask: "skills.subTitle14question2" },
        ],
      },
      {
        class: "meetings",
        name: "skills.subTitle15",
        subQuestions: [
          { inputValue: [1, 2, 3, 4], ask: "skills.subTitle15question1" },
          { inputValue: [1, 2, 3, 4], ask: "skills.subTitle15question2" },
        ],
      },
      {
        class: "strategy-MP",
        name: "skills.subTitle16",
        subQuestions: [
          { inputValue: [1, 2, 3, 4], ask: "skills.subTitle16question1" },
          { inputValue: [1, 2, 3, 4], ask: "skills.subTitle16question2" },
        ],
      },
      {
        class: "creation-MP",
        name: "skills.subTitle17",
        subQuestions: [
          { inputValue: [1, 2, 3, 4], ask: "skills.subTitle17question1" },
          { inputValue: [1, 2, 3, 4], ask: "skills.subTitle17question2" },
        ],
      },
      {
        class: "delivery",
        name: "skills.subTitle18",
        subQuestions: [
          { inputValue: [1, 2, 3, 4], ask: "skills.subTitle18question1" },
          { inputValue: [1, 2, 3, 4], ask: "skills.subTitle18question2" },
        ],
      },
      {
        class: "research",
        name: "skills.subTitle19",
        subQuestions: [
          { inputValue: [1, 2, 3, 4], ask: "skills.subTitle19question1" },
          { inputValue: [1, 2, 3, 4], ask: "skills.subTitle19question2" },
        ],
      },
      {
        class: "hands-on",
        name: "skills.subTitle20",
        subQuestions: [
          { inputValue: [1, 2, 3, 4], ask: "skills.subTitle20question1" },
          { inputValue: [1, 2, 3, 4], ask: "skills.subTitle20question2" },
        ],
      },
      {
        class: "leadership",
        name: "skills.subTitle21",
        subQuestions: [
          { inputValue: [1, 2, 3, 4], ask: "skills.subTitle21question1" },
          { inputValue: [1, 2, 3, 4], ask: "skills.subTitle21question2" },
        ],
      },
      {
        class: "access-capital",
        name: "skills.subTitle22",
        subQuestions: [
          { inputValue: [1, 2, 3, 4], ask: "skills.subTitle22question1" },
          { inputValue: [1, 2, 3, 4], ask: "skills.subTitle22question2" },
        ],
      },
      {
        class: "pitch-deck",
        name: "skills.subTitle23",
        subQuestions: [
          { inputValue: [1, 2, 3, 4], ask: "skills.subTitle23question1" },
          { inputValue: [1, 2, 3, 4], ask: "skills.subTitle23question2" },
        ],
      },
      {
        class: "working-investors",
        name: "skills.subTitle24",
        subQuestions: [
          { inputValue: [1, 2, 3, 4], ask: "skills.subTitle24question1" },
          { inputValue: [1, 2, 3, 4], ask: "skills.subTitle24question2" },
        ],
      },
    ],
  };

  componentDidMount() {
    this.createPieChart();
  }

  componentDidUpdate() {
    this.drawGraph();
  }

  createPieChart() {
    const svg = d3
      .select(".skills")
      .attr("width", "100%")
      .attr("viewBox", `0 0 ${this.width} ${this.height}`);

    svg
      .append("g")
      .attr("transform", `translate(${this.width / 2}, ${this.height / 2})`)
      .classed("chart-skills", true);

    svg
      .append("g")
      .attr("transform", `translate(${this.width / 2}, ${this.height / 2})`)
      .classed("inner-chart-skills", true);

    this.drawGraph();
  }

  drawGraph() {
    const arcs = d3.pie().value(1)(this.props.areas);

    const path = d3
      .arc()
      .outerRadius((d) => (d.data.value * this.width) / 15 - 31)
      .innerRadius(0);

    const update = d3
      .select(".chart-skills")
      .selectAll(".arc-skills")
      .data(arcs);

    update.exit().remove();

    update
      .enter()
      .append("path")
      .classed("arc-skills", true)
      .merge(update)
      .attr("fill", (_, i) => this.colorScaleAreas(i))
      .attr("d", path);

    const textPath = d3
      .arc()
      .outerRadius(this.width / 2)
      .innerRadius(this.width / 2);

    update
      .enter()
      .append("text")
      .attr("transform", (d, i) => {
        if (i === 2 || i === 3 || i === 4 || i === 5) {
          return `translate(${textPath.centroid(d)}) rotate(${
            ((d.startAngle + d.endAngle) * 90) / Math.PI + 180
          })`;
        } else {
          return `translate(${textPath.centroid(d)}) rotate(${
            ((d.startAngle + d.endAngle) * 90) / Math.PI
          })`;
        }
      })
      .attr("dy", (_, i) =>
        i === 2 || i === 3 || i === 4 || i === 5 ? -18 : 23
      )
      .attr("text-anchor", "middle")
      .attr("alignment-baseline", "middle")
      .style("color", "#000")
      .style("font-size", "2rem")
      .text((_, i) => this.props.areasDefault[i].abbr);

    const arcsInner = d3.pie().value(1)(this.props.subAreas);

    const pathInner = d3
      .arc()
      .outerRadius((d) => (d.data.value * this.width) / 15 - 20)
      .innerRadius(0);

    const updateInner = d3
      .select(".inner-chart-skills")
      .selectAll(".arc-skills")
      .data(arcsInner);

    updateInner.exit().remove();

    updateInner
      .enter()
      .append("path")
      .classed("arc-skills", true)
      .merge(updateInner)
      .attr("fill", (_, i) => this.colorScaleSubAreas(i))
      .attr("d", pathInner);
  }

  render() {
    const elements = this.props.subAreasDefault.map((a, i) => {
      if (i % this.subAreasPerNum === 0)
        return (
          <React.Fragment key={i}>
            <tr>
              <th
                className="text-left"
                colSpan="6"
                style={{
                  backgroundColor: this.colorScaleAreas(
                    Math.floor(i / this.subAreasPerNum)
                  ),
                }}
              >
                {this.props.t(
                  this.props.areasDefault[Math.floor(i / this.subAreasPerNum)]
                    .name
                )}
              </th>
            </tr>
            {a.subQuestions.map((b, j) => (
              <tr
                key={j}
                style={{ backgroundColor: this.colorScaleSubAreas(i) }}
              >
                {j === 0 ? (
                  <td rowSpan="2" style={{ padding: "0.3rem" }}>
                    {this.props.t(a.name)}
                  </td>
                ) : null}
                <td style={{ padding: "0.3rem" }}>{this.props.t(b.ask)}</td>
                {b.inputValue.map((c, k) => (
                  <td key={k} style={{ padding: "0" }}>
                    <label
                      style={{ padding: "0.75rem 0", width: "100%", margin: 0 }}
                    >
                      <input
                        type="radio"
                        name={`${a.class}-${j}`}
                        value={c}
                        style={{ transform: "scale(1.5)", padding: "0.3rem" }}
                        onChange={(e) =>
                          this.props.setValue(
                            +e.target.value,
                            i,
                            j,
                            this.subAreasPerNum
                          )
                        }
                        checked={
                          c ===
                          this.props.subAreas[i].subQuestions[j].checkedValue
                        }
                      />
                    </label>
                  </td>
                ))}
              </tr>
            ))}
          </React.Fragment>
        );
      else
        return (
          <React.Fragment key={i}>
            {a.subQuestions.map((b, j) => (
              <tr
                key={j}
                style={{ backgroundColor: this.colorScaleSubAreas(i) }}
              >
                {j === 0 ? (
                  <td
                    rowSpan="2"
                    style={{
                      backgroundColor: this.colorScaleSubAreas(i),
                      padding: "0.3rem",
                    }}
                  >
                    {this.props.t(a.name)}
                  </td>
                ) : null}
                <td style={{ padding: "0.3rem" }}>{this.props.t(b.ask)}</td>
                {b.inputValue.map((c, k) => (
                  <td key={k} style={{ padding: "0" }}>
                    <label
                      style={{ padding: "0.75rem 0", width: "100%", margin: 0 }}
                    >
                      <input
                        type="radio"
                        name={`${a.class}-${j}`}
                        value={c}
                        style={{ transform: "scale(1.5)", padding: "0.3rem" }}
                        onChange={(e) =>
                          this.props.setValue(
                            +e.target.value,
                            i,
                            j,
                            this.subAreasPerNum
                          )
                        }
                        checked={
                          c ===
                          this.props.subAreas[i].subQuestions[j].checkedValue
                        }
                      />
                    </label>
                  </td>
                ))}
              </tr>
            ))}
          </React.Fragment>
        );
    });

    return (
      <div className="row">
        <div className="col-md-4 pb-3">
          <div className="col-6 offset-3 col-md-12 offset-md-0">
            <svg
              className="skills"
              version="1.1"
              baseProfile="full"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="xMinYMin meet"
            ></svg>
          </div>
        </div>
        <div className="col-md-8">
          <h1>{this.props.t("assessment.skills")}</h1>
          <p>{this.props.t("skills.desc1")}</p>
          <p>{this.props.t("skills.desc2")}</p>
          <p>{this.props.t("skills.desc3")}</p>
          <table className="table table-dark table-bordered">
            <thead>
              <tr>
                <th style={{ padding: "0.3rem" }}>{this.props.t("area")}</th>
                <th style={{ padding: "0.3rem" }}>
                  {this.props.t("questions")}
                </th>
                <th style={{ padding: "0.3rem" }}>{this.props.t("notLike")}</th>
                <th style={{ padding: "0.3rem" }}>{this.props.t("notMuch")}</th>
                <th style={{ padding: "0.3rem" }}>
                  {this.props.t("somewhat")}
                </th>
                <th style={{ padding: "0.3rem" }}>{this.props.t("very")}</th>
              </tr>
            </thead>
            <tbody>{elements}</tbody>
          </table>
          <button
            className="btn btn-secondary float-right mt-3"
            onClick={() =>
              this.props.onChange(
                this.props.id,
                <div
                  dangerouslySetInnerHTML={{
                    __html: d3.select(".skills").node().outerHTML,
                  }}
                />
              )
            }
          >
            {this.props.t("save")}
          </button>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return state.skills;
}

export default connect(mapStateToProps, { setValue })(
  withTranslation()(Skills)
);
