import { SET_VALUE_S } from "../actionTypes";

export function setValue(val, subAreasId, subQuestionId, subAreasPerNum) {
  return {
    type: SET_VALUE_S,
    val,
    subAreasId,
    subQuestionId,
    subAreasPerNum,
  };
}
