import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./App.css";
import SevenFs from "./components/SevenFs";
import LeadershipMatrix from "./components/LeadershipMatrix";
import Assessment from "./components/EntrepreneurAssessment";
import Reset from "./components/Reset";
import DashboardWrapper from "./components/DashboardWrapper";

function App() {
  return (
    <Router>
      <div className="App">
        <Switch>
          <Route path="/7Fs/:id" component={SevenFs}></Route>
          <Route path="/:lgn/7Fs/:id" component={SevenFs}></Route>
          <Route path="/7Fs" component={SevenFs}></Route>
          <Route path="/:lgn/7Fs" component={SevenFs}></Route>
          <Route
            path="/LeadershipMatrix/:id"
            component={LeadershipMatrix}
          ></Route>
          <Route
            path="/:lgn/LeadershipMatrix/:id"
            component={LeadershipMatrix}
          ></Route>
          <Route path="/LeadershipMatrix" component={LeadershipMatrix}></Route>
          <Route
            path="/:lgn/LeadershipMatrix"
            component={LeadershipMatrix}
          ></Route>
          <Route path="/Assessment/:id" component={Assessment}></Route>
          <Route path="/:lgn/Assessment/:id" component={Assessment}></Route>
          <Route path="/Assessment" component={Assessment}></Route>
          <Route path="/:lgn/Assessment" component={Assessment}></Route>
          <Route path="/login" component={DashboardWrapper}></Route>
          <Route path="/cts" component={DashboardWrapper}></Route>
          <Route path="/reset/:token/:id" component={Reset}></Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
