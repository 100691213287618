import React, { Component } from "react";
import { connect } from "react-redux";
import { setOpacity } from "../store/actions/personalityStyle";
import { withTranslation } from "react-i18next";

class PersonalityStyle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      personalityStyle: "",
    };
    this.handleClick = this.handleClick.bind(this);
    this.generateSVG = this.generateSVG.bind(this);
  }

  static defaultProps = {
    questionAB: {
      ask: "personality.ABask",
      question1: "personality.ABquestion1",
      question2: "personality.ABquestion2",
    },
    questionCD: {
      ask: "personality.CDask",
      question1: "personality.CDquestion1",
      question2: "personality.CDquestion2",
    },
    answerAC: {
      title: "personality.ACtitle",
      content1: "personality.ACcontent1",
      content2: "personality.ACcontent2",
    },
    answerAD: {
      title: "personality.ADtitle",
      content1: "personality.ADcontent1",
      content2: "personality.ACcontent2",
    },
    answerBD: {
      title: "personality.BDtitle",
      content1: "personality.BDcontent1",
      content2: "personality.BDcontent2",
    },
    answerBC: {
      title: "personality.BCtitle",
      content1: "personality.BCcontent1",
      content2: "personality.BCcontent2",
    },
  };

  handleClick(d) {
    if (d === "personality.ABquestion1" || d === "personality.ABquestion2") {
      this.setState({ personalityStyle: d });
    } else if (d === "personality.CDquestion1" || d === "personality.CDquestion2") {
      const personalityStyle = this.state.personalityStyle + d;
      this.setState({ personalityStyle });
      let opacity;
      switch(personalityStyle) {
        case "personality.ABquestion1personality.CDquestion1":
          opacity = "AC";
          break;
        case "personality.ABquestion2personality.CDquestion1":
          opacity = "BC";
          break;
        case "personality.ABquestion1personality.CDquestion2":
          opacity = "AD";
          break;
        case "personality.ABquestion2personality.CDquestion2":
          opacity = "BD";
          break;
      }
      this.props.setOpacity(opacity);
    } else {
      this.props.onChange(this.props.id, this.generateSVG());
    }
  }

  generateSVG() {
    return (
      <svg
        version="1.1"
        baseProfile="full"
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="xMinYMin meet"
        viewBox="0 0 200 200"
      >
        <g opacity={this.props.AC}>
          <rect
            x="0.5"
            y="0.5"
            width="99"
            height="99"
            fill="#ffc107"
            stroke="black"
            strokeWidth="1"
            strokeOpacity={Math.floor(this.props.AC)}
          />
          <text
            x="50"
            y="50"
            textAnchor="middle"
            alignmentBaseline="middle"
            fontSize="1.5rem"
            fill="#fff"
          >
            I
          </text>
        </g>
        <g opacity={this.props.AD}>
          <rect
            x="100"
            y="0.5"
            width="99"
            height="99"
            fill="#28a745"
            stroke="black"
            strokeWidth="1"
            strokeOpacity={Math.floor(this.props.AD)}
          />
          <text
            x="150"
            y="50"
            textAnchor="middle"
            alignmentBaseline="middle"
            fontSize="1.5rem"
            fill="#fff"
          >
            S
          </text>
        </g>
        <g opacity={this.props.BD}>
          <rect
            x="100"
            y="100"
            width="99"
            height="99"
            fill="#007bff"
            stroke="black"
            strokeWidth="1"
            strokeOpacity={Math.floor(this.props.BD)}
          />
          <text
            x="150"
            y="150"
            textAnchor="middle"
            alignmentBaseline="middle"
            fontSize="1.5rem"
            fill="#fff"
          >
            C
          </text>
        </g>
        <g opacity={this.props.BC}>
          <rect
            x="0.5"
            y="100"
            width="99"
            height="99"
            fill="#dc3545"
            stroke="black"
            strokeWidth="1"
            strokeOpacity={Math.floor(this.props.BC)}
          />
          <text
            x="50"
            y="150"
            textAnchor="middle"
            alignmentBaseline="middle"
            fontSize="1.5rem"
            fill="#fff"
          >
            D
          </text>
        </g>
      </svg>
    );
  }

  render() {
    let element = "";
    switch (this.state.personalityStyle) {
      case "":
        element = (
          <>
            <p>{this.props.t(this.props.questionAB.ask)}</p>
            <p
              className="btn btn-secondary"
              onClick={() =>
                this.handleClick(this.props.questionAB.question1)
              }
            >
              {this.props.t(this.props.questionAB.question1)}
            </p>
            <p
              className="btn btn-secondary"
              onClick={() =>
                this.handleClick(this.props.questionAB.question2)
              }
            >
              {this.props.t(this.props.questionAB.question2)}
            </p>
          </>
        );
        break;
      case "personality.ABquestion1":
      case "personality.ABquestion2":
        element = (
          <>
            <p>{this.props.t(this.props.questionCD.ask)}</p>
            <p
              className="btn btn-secondary"
              onClick={() =>
                this.handleClick(this.props.questionCD.question1)
              }
            >
              {this.props.t(this.props.questionCD.question1)}
            </p>
            <p
              className="btn btn-secondary"
              onClick={() =>
                this.handleClick(this.props.questionCD.question2)
              }
            >
              {this.props.t(this.props.questionCD.question2)}
            </p>
          </>
        );
        break;
      case "personality.ABquestion1personality.CDquestion1":
        element = (
          <>
            <div
              className="btn btn-warning text-white"
              onClick={this.handleClick}
            >
              <p>{this.props.t(this.props.answerAC.title)}</p>
              <p>{this.props.t(this.props.answerAC.content1)}</p>
              <p>{this.props.t(this.props.answerAC.content2)}</p>
            </div>
            <button
              className="btn btn-secondary float-right mt-3"
              onClick={this.handleClick}
            >
              {this.props.t("save")}
            </button>
          </>
        );
        break;
      case "personality.ABquestion1personality.CDquestion2":
        element = (
          <>
            <div className="btn btn-success" onClick={this.handleClick}>
              <p>{this.props.t(this.props.answerAD.title)}</p>
              <p>{this.props.t(this.props.answerAD.content1)}</p>
              <p>{this.props.t(this.props.answerAD.content2)}</p>
            </div>
            <button
              className="btn btn-secondary float-right mt-3"
              onClick={this.handleClick}
            >
              {this.props.t("save")}
            </button>
          </>
        );
        break;
      case "personality.ABquestion2personality.CDquestion2":
        element = (
          <>
            <div className="btn btn-primary" onClick={this.handleClick}>
              <p>{this.props.t(this.props.answerBD.title)}</p>
              <p>{this.props.t(this.props.answerBD.content1)}</p>
              <p>{this.props.t(this.props.answerBD.content2)}</p>
            </div>
            <button
              className="btn btn-secondary float-right mt-3"
              onClick={this.handleClick}
            >
              {this.props.t("save")}
            </button>
          </>
        );
        break;
      case "personality.ABquestion2personality.CDquestion1":
        element = (
          <>
            <div className="btn btn-danger" onClick={this.handleClick}>
              <p>{this.props.t(this.props.answerBC.title)}</p>
              <p>{this.props.t(this.props.answerBC.content1)}</p>
              <p>{this.props.t(this.props.answerBC.content2)}</p>
            </div>
            <button
              className="btn btn-secondary float-right mt-3"
              onClick={this.handleClick}
            >
              {this.props.t("save")}
            </button>
          </>
        );
        break;
      default:
        break;
    }

    return (
      <div className="row">
        <div className="col-md-3 pb-3">
          <h5>{this.props.t("personality.question")}</h5>
          <div className="col-6 offset-3 col-md-12 offset-md-0">
            {this.generateSVG()}
          </div>
        </div>
        <div className="col-md-9">
          <h2>{this.props.t("assessment.personality")}</h2>
          {element}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return state.personalityStyle;
}

export default connect(mapStateToProps, { setOpacity })(
  withTranslation()(PersonalityStyle)
);
