import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { UserContext } from "./UserContext";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import Flatpickr from "react-flatpickr";
import Lightbox from "react-lightbox-component";
import Modal from "react-modal";
import { deserialize } from "react-serialize";
import Input from "./Input";

import "@splidejs/splide/dist/css/themes/splide-sea-green.min.css";
import "flatpickr/dist/themes/dark.css";
import "react-lightbox-component/build/css/index.css";
import "./scss/Dashboard.scss";

class Dashboard extends Component {
  static contextType = UserContext;
  constructor(props) {
    super(props);

    this.state = {
      currentView: "dashboard",
      viewportWidth: 0,
      carouselHeight: 0,
      carouselPages: 10,
      modalHeadHeight: 0,
      clients: [],
      tools: [
        { name: "Mentoring Guidelines", showToolModal: false },
        {
          name: "Overview of Pocket Mentor tools & Exercises",
          showToolModal: false,
        },
        { name: "Tutorial for CRTS", showToolModal: false },
      ],
      client: {
        index: -1,
        name: "",
        email: "",
        contact: "",
        otherContact: "",
        project: "",
        notes: "",
        meetings: [],
        svg: [],
        assessmentId: "",
      },
      showInputEle: {
        name: false,
        email: false,
        contact: false,
        otherContact: false,
        project: false,
        notes: false,
      },
      meeting: {
        _id: "",
        time: "",
        nextTime: "",
        challenges: "",
        homework: "",
        homeworkDue: "",
        onTime: false,
        quality: 1,
        note: "",
      },
      error: "",
    };

    this.changeView = this.changeView.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
    this.changeState = this.changeState.bind(this);
    this.handleClientClick = this.handleClientClick.bind(this);
    this.handleDeleteClient = this.handleDeleteClient.bind(this);
    this.currentView = this.currentView.bind(this);
    this.handleClientInfoChange = this.handleClientInfoChange.bind(this);
    this.handleOpenClientDeleteModal =
      this.handleOpenClientDeleteModal.bind(this);
    this.handleCloseClientDeleteModal =
      this.handleCloseClientDeleteModal.bind(this);
    this.fillClientDeleteModal = this.fillClientDeleteModal.bind(this);
    this.handleAddMeeting = this.handleAddMeeting.bind(this);
    this.handleDeleteMeeting = this.handleDeleteMeeting.bind(this);
    this.handleOpenToolModal = this.handleOpenToolModal.bind(this);
    this.handleCloseToolModal = this.handleCloseToolModal.bind(this);
    this.seleteToolModal = this.seleteToolModal.bind(this);
    this.handleOpenMeetingModal = this.handleOpenMeetingModal.bind(this);
    this.handleCloseMeetingModal = this.handleCloseMeetingModal.bind(this);
    this.fillMeetingModal = this.fillMeetingModal.bind(this);
    this.handleMeetingDetail = this.handleMeetingDetail.bind(this);
    this.handleOpenMeetingDeleteModal =
      this.handleOpenMeetingDeleteModal.bind(this);
    this.handleCloseMeetingDeleteModal =
      this.handleCloseMeetingDeleteModal.bind(this);
    this.fillMeetingDeleteModal = this.fillMeetingDeleteModal.bind(this);
  }

  changeView(view, clearClientContent = 0) {
    if (clearClientContent) {
      this.setState({
        client: {
          _id: "",
          index: -1,
          name: "",
          email: "",
          contact: "",
          otherContact: "",
          project: "",
          notes: "",
          meetings: [],
          svg: [],
          assessmentId: "",
        },
        currentView: view,
        error: "",
      });
    } else {
      this.setState({
        currentView: view,
        error: "",
      });
    }
  }

  handleLogout(event) {
    event.preventDefault();
    const { token, setToken } = this.context;

    fetch("https://twem.ca/api/logout/", {
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((data) => data.json())
      .then((data) => {
        if (data.message === "Successfully") {
          setToken("");
          this.props.history.push("/login/");
        }
      });
  }

  changeState() {
    let heightPerPage = 70;
    let carouselPercent = 0.6;
    let carouselHeight =
      Math.floor((heightPerPage * this.state.clients.length) / 2) <
      window.innerHeight * carouselPercent
        ? Math.floor((heightPerPage * this.state.clients.length) / 2)
        : window.innerHeight * carouselPercent;
    let carouselPages = Math.ceil(carouselHeight / heightPerPage);

    if (window.innerWidth < 768) {
      carouselHeight = window.innerHeight * carouselPercent;
      carouselPages = Math.ceil(carouselHeight / heightPerPage);
    }

    this.setState({
      viewportWidth: window.innerWidth,
      carouselHeight,
      carouselPages,
    });
  }

  handleClientClick(i) {
    if (this.state.clients[i].email) {
      const { token, setToken } = this.context;
      fetch(
        `https://twem.ca/api/assessment/email/${this.state.clients[i].email}`,
        {
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((data) => data.json(data))
        .then((data) => {
          this.setState({
            client: {
              _id: this.state.clients[i]._id,
              index: i,
              name: this.state.clients[i].name,
              email: this.state.clients[i].email,
              contact: this.state.clients[i].contact,
              otherContact: this.state.clients[i].otherContact,
              project: this.state.clients[i].project,
              notes: this.state.clients[i].notes,
              svg: data.svg ? data.svg : [],
              assessmentId: data.id ? data.id : "",
              meetings: this.state.clients[i].meetings.map((meeting) => ({
                ...meeting,
                showMeetingModal: false,
              })),
            },
          });
        });
    } else {
      this.setState({
        client: {
          _id: this.state.clients[i]._id,
          index: i,
          name: this.state.clients[i].name,
          email: this.state.clients[i].email,
          contact: this.state.clients[i].contact,
          otherContact: this.state.clients[i].otherContact,
          project: this.state.clients[i].project,
          notes: this.state.clients[i].notes,
          svg: [],
          assessmentId: "",
          meetings: this.state.clients[i].meetings.map((meeting) => ({
            ...meeting,
            showMeetingModal: false,
          })),
        },
      });
    }

    this.changeView("newClient");
  }

  handleDeleteClient(i) {
    const { token, setToken } = this.context;

    fetch("https://twem.ca/api/client", {
      method: "DELETE",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
      body: JSON.stringify({
        client: this.state.clients[i],
      }),
    })
      .then((data) => data.json())
      .then((data) => {
        if (data.message === "Successfully") {
          this.setState({ clients: data.clients });
        }
      });
  }

  handleOpenClientDeleteModal(i) {
    this.setState({
      clients: this.state.clients.map((c, idx) =>
        idx === i ? { ...c, showClientDeleteModal: true } : c
      ),
    });
  }

  handleCloseClientDeleteModal(i) {
    this.setState({
      clients: this.state.clients.map((c, idx) =>
        idx === i ? { ...c, showClientDeleteModal: false } : c
      ),
    });
  }

  fillClientDeleteModal(i) {
    return (
      <div className="modal-content">
        <div className="modal-header align-items-center bg-danger">
          <h1 className="modal-title text-white">
            You are removing{" "}
            {`${this.state.clients[i].name} - ${this.state.clients[i].project}`}
          </h1>
          <button
            type="button"
            className="close text-white"
            onClick={() => this.handleCloseClientDeleteModal(i)}
          >
            <span style={{ fontSize: "2.5rem" }}>&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <h1>Do you want to delete this record?</h1>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            onClick={() => this.handleCloseClientDeleteModal(i)}
          >
            Close
          </button>
          <button
            type="button"
            class="btn btn-primary"
            onClick={() => this.handleDeleteClient(i)}
          >
            Delete
          </button>
        </div>
      </div>
    );
  }

  handleClientInfoChange(event) {
    event.preventDefault();
    const { token, setToken } = this.context;

    if (this.state.client.index == -1) {
      const client = {
        name: this.state.client.name,
        project: this.state.client.project,
        email: this.state.client.email,
        contact: this.state.client.contact,
        otherContact: this.state.client.otherContact,
        notes: this.state.client.notes,
        meetings: this.state.client.meetings,
      };

      fetch("https://twem.ca/api/client", {
        method: "POST",
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        }),
        body: JSON.stringify({ client }),
      })
        .then((data) => data.json())
        .then((data) => {
          if (data.message === "Successfully") {
            this.changeView("dashboard");
            this.setState({ clients: data.clients }, () => this.changeState());
          }
        });
    } else {
      const client = {
        _id: this.state.client._id,
        name: this.state.client.name,
        project: this.state.client.project,
        email: this.state.client.email,
        contact: this.state.client.contact,
        otherContact: this.state.client.otherContact,
        notes: this.state.client.notes,
        meetings: this.state.client.meetings,
      };

      fetch("https://twem.ca/api/client", {
        method: "PUT",
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        }),
        body: JSON.stringify({ client }),
      })
        .then((data) => data.json())
        .then((data) => {
          if (data.message === "Successfully") {
            this.changeView("dashboard");
            this.setState({ clients: data.clients });
          }
        });
    }
  }

  handleAddMeeting(event) {
    event.preventDefault();

    const duplicate = this.state.client.meetings.find(
      (m) =>
        new Date(m.time).getTime() ===
        new Date(this.state.meeting.time).getTime()
    );

    if (duplicate) {
      this.setState({ error: "This time has existed!" });
      return;
    }

    const { token, setToken } = this.context;

    fetch("https://twem.ca/api/meeting", {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
      body: JSON.stringify({
        _id: this.state.client._id,
        meeting: {
          time: this.state.meeting.time,
          nextTime: "",
          challenges: "",
          homework: "",
          homeworkDue: "",
          onTime: false,
          quality: 1,
          note: "",
        },
      }),
    })
      .then((data) => data.json())
      .then((data) => {
        if (data.message === "Successfully") {
          this.setState({
            client: { ...this.state.client, ...data.client },
            clients: this.state.clients.map((client) =>
              client && client._id === data.client._id
                ? { ...this.state.client, ...data.client }
                : client
            ),
            error: "",
          });
        }
      });
  }

  handleDeleteMeeting(id) {
    const { token, setToken } = this.context;

    fetch("https://twem.ca/api/meeting", {
      method: "DELETE",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
      body: JSON.stringify({
        _id: this.state.client._id,
        meetingId: id,
      }),
    })
      .then((data) => data.json())
      .then((data) => {
        if (data.message === "Successfully") {
          this.setState({
            client: { ...this.state.client, ...data.client },
            clients: this.state.clients.map((client) =>
              client && client._id === data.client._id
                ? { ...this.state.client, ...data.client }
                : client
            ),
          });
        }
      });
  }

  handleOpenToolModal(name) {
    this.setState({
      tools: this.state.tools.map((tool) =>
        tool.name === name ? { ...tool, showToolModal: true } : tool
      ),
    });
  }

  handleCloseToolModal(name) {
    this.setState({
      tools: this.state.tools.map((tool) =>
        tool.name === name ? { ...tool, showToolModal: false } : tool
      ),
    });
  }

  seleteToolModal(name) {
    switch (name) {
      case "Mentoring Guidelines":
        return (
          <div className="modal-content">
            <div className="modal-header align-items-center bg-success">
              <h1 className="modal-title text-white">
                Mentoring Orientation framework
              </h1>
              <button
                type="button"
                className="close text-white"
                onClick={() => this.handleCloseToolModal(name)}
              >
                <span style={{ fontSize: "2.5rem" }}>&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <h5>Overview</h5>
              <p>
                Mentors differ from coaches by being those who have
                been-there-done-that. Great coaches may also have business
                experience. In our case, it is expected that mentors have deep
                industry, entrepreneur, and/or investment experience. Ideally in
                a similar space as the entrepreneurs they are working with.
              </p>
              <p>
                Mentors work with our entrepreneurs without expectation of
                reward or compensation. They share their knowledge, experience,
                and guidance freely.
              </p>
              <p>
                Sometimes mentors may make introductions to others in their
                networks where needed. Any introduction of products or services
                that include fees must be discussed with the Managing Director.
                The mentoring is not to be used to influence entrepreneurs
                towards products and services.
              </p>
              <p>Mentoring is a vital part of the success of entrepreneurs.</p>
              <h5>Guidelines</h5>
              <ul>
                <li>Keep asking “why” to get to the real problem</li>
                <li>Exercise empathy, entrepreneurship is hard – remember?</li>
                <li>
                  Be humble, add what you know to what they know. Learn from
                  them, too
                </li>
                <li>
                  Be honest. Others might not tell them the truth. Do so gently
                  and with respect. Being clear is kind, and unclear is unkind
                </li>
                <li>Be responsive. They need you and sometimes it’s timely</li>
                <li>Treat their information as private. Respect their IP</li>
                <li>State what is fact and what is opinion</li>
                <li>
                  Guide rather than control. If they don’t listen to you, it’s
                  their company
                </li>
                <li>
                  Mentor or do not, there is no try. Please don’t play
                  half-hearted. We expect the same from them
                </li>
                <li>
                  Expect nothing in return. You are not there to promote
                  anything other than their success
                </li>
                <li>
                  Spread the word. If you are enjoying mentoring, please tell
                  others #fuse42mentoring
                </li>
              </ul>
              <p>If these align perfectly with you, step forward.</p>
              <h5>Types of Mentorship:</h5>
              <p>
                We have mentors who can spend a fair bit of time with the
                entrepreneur team and those who can weigh in on specific areas.
              </p>
              <ul>
                <li>Sensei Mentors</li>
                <li>Ninja Mentors</li>
              </ul>
              <h5>Becoming an Official Entrepreneur Roadmap Mentor</h5>
              <p>
                Regardless of your choice to mentor with us, you are welcome to
                access our mentoring framework for entrepreneurs. TERM.coach
              </p>
              <p>
                The best way to get involved is by getting Certified. The option
                for Certification will be listed when you click Register below.
              </p>
              <p>
                To find out what our ecosystem is like, come attend any of the
                events where entrepreneurs are gathered - like Demo Days. As
                well, we have speed pitching events where you will get to see a
                number of teams in their early stages and share your insights
                and observations. This will give you a good idea, like CDL,
                where you might want to apply your talents.
              </p>
              <p>
                You may also choose to be a guest speaker. This is a paid gig
                and will be at the discretion of the Managing Director. Please
                add that to the application below.
              </p>
              <p>
                As you start mentoring, your interaction with the entrepreneurs
                will be self-directed, along with the needs of the entrepreneurs
                you work with or directed through the Managing Director.
              </p>
              <h5>A Few Other Tips on Mentoring</h5>
              <p>
                Stay in touch! We only know as much as you tell us about your
                interest, your availability, and your ability. Keep the Managing
                Director in the loop.
              </p>
              <p>
                Know your reasons for mentoring. Our Mentors are not compensated
                throughout the program and should expect nothing in return. The
                mentors we have worked with that have this mindset always end up
                getting the most out of the program.
              </p>
              <p>
                Much of what you’re exposed to is highly confidential, sometimes
                this includes the entrepreneur’s participation in our programs.
                Please do not discuss the company outside the walls of your
                discussions without the entrepreneur’s express permission.
              </p>
              <h5>Thank You</h5>
              <p>
                Mentors are a vital part of what makes entrepreneurship awesome.
                Thank you for your interest in giving your time and energy to
                these future leaders.
              </p>
            </div>
          </div>
        );
      case "Overview of Pocket Mentor tools & Exercises":
        return (
          <div className="modal-content">
            <div className="modal-header align-items-center bg-success">
              <h1 className="modal-title text-white">
                Overview of Pocket Mentor
              </h1>
              <button
                type="button"
                className="close text-white"
                onClick={() => this.handleCloseToolModal(name)}
              >
                <span style={{ fontSize: "2.5rem" }}>&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-6">
                  <Lightbox
                    images={[
                      {
                        src: "/Site Map PM Lite.jpg",
                      },
                    ]}
                    thumbnailWidth="100%"
                    thumbnailHeight="auto"
                  />
                </div>
                <div className="col-6">
                  <Lightbox
                    images={[
                      {
                        src: "/Site Map PM Advanced.jpg",
                      },
                    ]}
                    thumbnailWidth="100%"
                    thumbnailHeight="auto"
                  />
                </div>
              </div>
              <a href="/Site Map PM Lite.pdf" target="_blank">
                Site Map PM Lite PDF version
              </a>
              <br />
              <a href="/Site Map PM Advanced.pdf" target="_blank">
                Site Map PM Advanced PDF version
              </a>
              <p>
                As you talk with your mentee, you will find their Big Immediate
                Challenge (BIC) usually falls within one of these seven areas.
                Inside each of those areas are tools and then inside each tool
                are exercises. All of these can be completed by the entrepreneur
                and shared with you inside the app.
              </p>
              <p>
                <strong>THEIR MINDSET:</strong> If their BIC has to do with
                their personal balance, life plans, personal planning, personal
                vision or health of the entrepreneur themselves, send them to
                work through tools in this section.
              </p>
              <ul>
                <li>
                  Entrepreneur Assessment (personality, grit, EI/EQ,
                  Intellectual humility, skill-sets, challenging scenarios).
                  When they do this assessment, if they use the same email you
                  have entered for their contact in their profile, their
                  assessment will be shared to their record in your reports.
                </li>
                <li>
                  7Fs (understanding where they might be out of balance: Family,
                  Friends, Finances, Fitness, Faith, Fun, Field)
                </li>
                <li>Blueprint your Life (building a vision for your life)</li>
                <li>
                  Buckets exercise (understanding what fills and empties their
                  energy buckets)
                </li>
                <li>
                  Ideal Week (mapping out a framework calendar week to ensure
                  all the elements get done)
                </li>
                <li>Get Stuff Done (building better productivity)</li>
                <li>
                  Make Better Decisions (an overview on tools and mind maps to
                  help anyone make better decisions)
                </li>
                <li>
                  Q6 Debrief (an effective and simple tool to do after every
                  project, interaction, meeting... everything; aids agility and
                  in extracting all the lessons from each meeting)
                </li>
              </ul>
              <p>
                <strong>THEIR CUSTOMERS:</strong> If their BIC has to do with
                how they find, understand, and work with their customers, how
                they tell the story of their business, and how they
                differentiate from the competition, send them to this section.
              </p>
              <ul>
                <li>Their Ideal Customer Profile (ICP)</li>
                <li>
                  What do their customers want from them – their Job To Be Done
                  (JTBD)
                </li>
                <li>
                  How they differentiate themselves (how they explain and
                  demonstrate a difference from their competition)
                </li>
                <li>
                  How their customers come to know about them and buy something
                  from them (the Pirate Metrics funnel)
                </li>
                <li>
                  How to explain their business through a simple sentence to
                  three sentence pitch.
                </li>
              </ul>
              <p>
                <strong>THEIR MONEY:</strong> If their BIC has to do with
                finances (cash-flow, budgets, metrics), the viability of the
                business, or building a sales funnel and associated costs, send
                them to this section.
              </p>
              <ul>
                <li>
                  Go/No-Go (Working through how to validate the success of
                  making money in the business)
                </li>
                <li>Budget template for starting to know their numbers</li>
                <li>
                  Cash Flow (an Excel tool for monitoring recurring and timely
                  expenses, income and cash flows)
                </li>
                <li>
                  Understanding the numbers to watch and how to monitor them
                  (KPI dashboard)
                </li>
              </ul>
              <p>
                <strong>THEIR PLANNING:</strong> If their BIC has to do planning
                and what to do next, Step 04 is one of the most powerful tools
                we have.
              </p>
              <ul>
                <li>
                  Telescope to Microscope (putting together a 5-10 year plan,
                  narrowing to 3-year pillars, narrowing to the coming year,
                  narrowing to 90-day projects, and ultimately, what must be
                  done this week)
                </li>
                <li>
                  1 Page Blueprint (similar to a Lean Model Canvas or 1 page
                  business plan for sharing)
                </li>
                <li>
                  Overview of project planning and management (a practical
                  business plan summarizing what needs to happen in each area of
                  the business each quarter, along with the associated costs)
                </li>
              </ul>
              <p>
                <strong>THEIR TEAM:</strong> If their BIC is people problems
                (how to hire, who to hire, when to hire, and how to keep a team
                on track), the tools in Step 05 are good for any size business.
              </p>
              <ul>
                <li>
                  Team Alignment (how to get the whole team rowing in the same
                  direction)
                </li>
                <li>
                  Core 4 (an explanation of each of the 4 most important
                  meetings - and only meetings - to have, including templates
                  for the weekly meeting)
                </li>
                <li>
                  Hiring Blueprint (a process for hiring the best people -
                  interview/reference checks, people fit tools, and far more)
                </li>
              </ul>
              <p>
                <strong>FINANCING THEIR BUSINESS:</strong> If their BIC has to
                do with building better processes, planning, and ensuring the
                business passes the "bus test", Step 06 will help.
              </p>
              <ul>
                <li>Helping them have perspective as their first investor</li>
                <li>How to initially finance and Start Their Runway</li>
                <li>How to maintain and Sustain Their Journey</li>
                <li>
                  Then to ultimately Scale Their Growth through outside
                  investment
                </li>
                <li>Also tips about Pitch Decks</li>
                <li>
                  Project Planner (an outline to set up successful projects)
                </li>
                <li>
                  Project Log Tracker (a way to keep track of who owns each
                  project, and track its progress. Ideally done at weekly
                  meetings)
                </li>
              </ul>
              <p>
                <strong>THEIR LEADERSHIP & MATURING:</strong> If the BIC has to
                do with delegation, structuring leadership, solving problems
                with their teams, or building processes, This section will help.
              </p>
              <ul>
                <li>
                  Delegation (how to identify where the leaders - or anyone -
                  should be spending their time, and what they should delegate)
                </li>
                <li>
                  Leadership Matrix (how to ensure key roles are filled and
                  measured, and the overall strength of the leadership team)
                </li>
                <li>
                  Solve Big Problems (an outline on how to work through issues
                  in meetings)
                </li>
                <li>
                  Build Processes (an outline to start or improve the company's
                  manual for "how it's done here")
                </li>
                <li>
                  Q20 (a quick health checkup for the business to identify areas
                  needing improvement. Usually better for an established
                  business)
                </li>
                <li>
                  Kaizen (an overview of the process of Constant Improvement)
                </li>
                <p>
                  Using and prescribing these tools is made significantly easier
                  by getting certified as an Entrepreneur Roadmap/Pocket Mentor
                  mentor. This is $378 one time and takes about 2 hours. Request
                  certification at{" "}
                  <a href="https://PocketMentor.ca/training" target="_blank">
                    PocketMentor.ca/training
                  </a>{" "}
                  You can also get support, connections, and any questions
                  answered at{" "}
                  <a href="mailto:hello@entrepreneurroadmap.ca">
                    hello@entrepreneurroadmap.ca
                  </a>
                  .
                </p>
                <p>
                  If you are looking for any handy resources, you can see a list
                  at{" "}
                  <a href="https://PocketMentor.ca/resources" target="_blank">
                    PocketMentor.ca/resources
                  </a>
                </p>
              </ul>
            </div>
          </div>
        );
      case "Tutorial for CRTS":
        return (
          <div className="modal-content">
            <div className="modal-header align-items-center bg-success">
              <h1 className="modal-title text-white">
                Tutorial for Coaching Relationship Tracking System
              </h1>
              <button
                type="button"
                className="close text-white"
                onClick={() => this.handleCloseToolModal(name)}
              >
                <span style={{ fontSize: "2.5rem" }}>&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <p>
                Each time you log in, your dashboard will show each of your
                mentoring relationships and a button to add a “NEW” one.
              </p>
              <p>
                When you click “NEW” you will be asked a few identifying
                questions about the client. You may need to meet them and return
                to add any unknown information.
              </p>
              <h5>Add new relationship (or edit existing):</h5>
              <p>
                Questions to consider when you get to know your client (Who,
                What, Where, When, Why, and How)
              </p>
              <ul>
                <li>Who are they and where are they going?</li>
                <li>Who are their clients and what is their solution?</li>
                <li>
                  Why did they choose this business model and how did they get
                  involved?
                </li>
                <li>
                  What does their solution do and how will they get there?
                </li>
                <li>What is on the biggest thing on their mind right now?</li>
                <li>What is holding them back to get to the next level?</li>
                <li>
                  If they could wave a magic wand and solve one thing, what
                  would it be?
                </li>
              </ul>
              <p>
                When you click on any Current Relationships from the dashboard,
                you will be able to edit their main contact information as
                needed.
              </p>
              <p>
                Once within the relationship record, you can click “NEW” to add
                a new meeting record to capture each meeting with that client.
              </p>
              <h5>Each client meeting has a few questions to answer:</h5>
              <ul>
                <li>Their Big Immediate Challenge (BIC)</li>
                <li>What homework you assign them</li>
                <li>When the next meeting will be</li>
                <li>When their homework is due to you</li>
                <li>Notes for next time</li>
              </ul>
              <h5>Detail on each meeting:</h5>
              <p>
                Each meeting, you will likely be catching up, hearing new and
                developing ideas and problems.
              </p>
              <p>
                Listen and ask questions to ultimately identify their current
                Biggest Immediate Challenge (BIC)
              </p>
              <p>
                Then, based on their BIC, assign homework, and record it along
                with dates and expectations (see outline below of tools and
                exercises in the app to pick from)
              </p>
              <p>
                Before the next meeting, review their homework and take notes
                for the next meeting as well as anything else to remember.
              </p>
              <p>
                Having this form open during your meeting will allow you to take
                notes and prescribe the right supports.
              </p>
              <p>
                Depending on the size of the team, a good tool to identify areas
                of improvement is the Q20, found at{" "}
                <a
                  href="https://www.entrepreneurroadmap.ca/Q20"
                  target="_blank"
                >
                  EntrepreneurRoadmap.ca/Q20
                </a>
                . Have them share the results with you once completed.
              </p>
            </div>
          </div>
        );
    }
  }

  handleOpenMeetingModal(m) {
    this.setState({
      client: {
        ...this.state.client,
        meetings: this.state.client.meetings.map((e) =>
          m._id === e._id
            ? { ...e, showMeetingModal: true }
            : { ...e, showMeetingModal: false }
        ),
      },
      meeting: {
        _id: m._id ? m._id : "",
        time: m.time ? m.time : "",
        nextTime: m.nextTime ? m.nextTime : "",
        challenges: m.challenges ? m.challenges : "",
        homework: m.homework ? m.homework : "",
        homeworkDue: m.homeworkDue ? m.homeworkDue : "",
        onTime: m.onTime ? m.onTime : false,
        quality: m.quality ? m.quality : 1,
        note: m.note ? m.note : "",
      },
    });
  }

  handleCloseMeetingModal(id) {
    this.setState({
      client: {
        ...this.state.client,
        meetings: this.state.client.meetings.map((m) =>
          m._id === id ? { ...m, showMeetingModal: false } : m
        ),
      },
    });
  }

  fillMeetingModal(m, bg) {
    return (
      <div className="modal-content">
        <div className={`modal-header align-items-center ${bg}`}>
          <h1 className="modal-title text-white">
            Meeting Record at{" "}
            {`${new Date(m.time).toLocaleDateString()} -
              ${new Date(m.time).toLocaleTimeString()}`}
          </h1>
          <button
            type="button"
            className="close text-white"
            onClick={() => this.handleCloseMeetingModal(m._id)}
          >
            <span style={{ fontSize: "2.5rem" }}>&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <form>
            <div className="form-group">
              <label for="challenges">
                What are the top 2-3 Big Immediate Challenges (BIC) they are
                facing?
              </label>
              <textarea
                className="form-control"
                id="challenges"
                rows="3"
                value={this.state.meeting.challenges}
                onChange={(e) =>
                  this.setState({
                    meeting: {
                      ...this.state.meeting,
                      challenges: e.target.value,
                    },
                  })
                }
              ></textarea>
            </div>
            <div className="form-group">
              <label for="homework">
                What homework did you assign for them? (exercises from
                ROOTapp.ca, books, etc)
              </label>
              <textarea
                className="form-control"
                id="homework"
                rows="3"
                value={this.state.meeting.homework}
                onChange={(e) =>
                  this.setState({
                    meeting: {
                      ...this.state.meeting,
                      homework: e.target.value,
                    },
                  })
                }
              ></textarea>
            </div>
            <div className="form-group">
              <div className="form-row">
                <div className="col">
                  <label className="d-block">
                    When is your next appointment with them?
                  </label>
                  <Flatpickr
                    options={{
                      enableTime: true,
                      dateFormat: "Y-m-d H:i",
                    }}
                    value={this.state.meeting.nextTime}
                    onChange={([time]) =>
                      this.setState({
                        meeting: { ...this.state.meeting, nextTime: time },
                      })
                    }
                  />
                </div>
                <div className="col">
                  <label className="d-block">
                    Did they get the homework done and on time?
                  </label>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="inlineRadioOptions"
                      checked={this.state.meeting.onTime}
                      onChange={(e) =>
                        this.setState({
                          meeting: { ...this.state.meeting, onTime: true },
                        })
                      }
                    />
                    <label className="form-check-label">Yes</label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="inlineRadioOptions"
                      checked={!this.state.meeting.onTime}
                      onChange={(e) =>
                        this.setState({
                          meeting: { ...this.state.meeting, onTime: false },
                        })
                      }
                    />
                    <label className="form-check-label">No</label>
                  </div>
                </div>
              </div>
            </div>
            <div className="form-group">
              <div className="form-row">
                <div className="col">
                  <label className="d-block">
                    When is their homework due to you?
                  </label>
                  <Flatpickr
                    options={{
                      enableTime: true,
                      dateFormat: "Y-m-d H:i",
                    }}
                    value={this.state.meeting.homeworkDue}
                    onChange={([time]) =>
                      this.setState({
                        meeting: { ...this.state.meeting, homeworkDue: time },
                      })
                    }
                  />
                </div>
                <div className="col">
                  <div className="form-group">
                    <label>
                      How was the quality of their homework?{" "}
                      {`${this.state.meeting.quality}`}
                    </label>
                    <input
                      type="range"
                      className="form-control-range"
                      min="1"
                      max="5"
                      value={this.state.meeting.quality}
                      onChange={(e) =>
                        this.setState({
                          meeting: {
                            ...this.state.meeting,
                            quality: e.target.value,
                          },
                        })
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="form-group">
              <label for="note">
                Anything you want to note to talk about next meeting? (future
                outcomes, notes of record, general observations)
              </label>
              <textarea
                className="form-control"
                id="note"
                rows="3"
                value={this.state.meeting.note}
                onChange={(e) =>
                  this.setState({
                    meeting: { ...this.state.meeting, note: e.target.value },
                  })
                }
              ></textarea>
            </div>
            <button className="btn btn-dark" onClick={this.handleMeetingDetail}>
              Save
            </button>
          </form>
        </div>
      </div>
    );
  }

  handleMeetingDetail(event) {
    event.preventDefault();
    const { token, setToken } = this.context;

    fetch("https://twem.ca/api/meeting", {
      method: "PUT",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
      body: JSON.stringify({
        clientId: this.state.client._id,
        meetingId: this.state.meeting._id,
        meeting: {
          time: this.state.meeting.time,
          nextTime: this.state.meeting.nextTime,
          challenges: this.state.meeting.challenges,
          homework: this.state.meeting.homework,
          homeworkDue: this.state.meeting.homeworkDue,
          onTime: this.state.meeting.onTime,
          quality: this.state.meeting.quality,
          note: this.state.meeting.note,
        },
      }),
    })
      .then((data) => data.json())
      .then((data) => {
        if (data.message === "Successfully") {
          this.setState(
            {
              clients: data.clients,
              client: {
                ...this.state.client,
                ...data.clients.find((c) => c._id === this.state.client._id),
              },
            },
            () => {
              this.handleCloseMeetingModal(this.state.meeting._id);
              this.changeView("newClient");
            }
          );
        }
      });
  }

  handleOpenMeetingDeleteModal(m) {
    this.setState({
      client: {
        ...this.state.client,
        meetings: this.state.client.meetings.map((e) =>
          m._id === e._id
            ? { ...e, showMeetingDeleteModal: true }
            : { ...e, showMeetingDeleteModal: false }
        ),
      },
    });
  }
  toTimeString;

  handleCloseMeetingDeleteModal(id) {
    this.setState({
      client: {
        ...this.state.client,
        meetings: this.state.client.meetings.map((m) =>
          m._id === id ? { ...m, showMeetingDeleteModal: false } : m
        ),
      },
    });
  }

  fillMeetingDeleteModal(m) {
    return (
      <div className="modal-content">
        <div className="modal-header align-items-center bg-danger">
          <h1 className="modal-title text-white">
            You are removing{" "}
            {`${new Date(m.time).toLocaleDateString()} - 
                ${new Date(m.time).toLocaleTimeString()}`}
          </h1>
          <button
            type="button"
            className="close text-white"
            onClick={() => this.handleCloseMeetingDeleteModal(m._id)}
          >
            <span style={{ fontSize: "2.5rem" }}>&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <h1>do you want to delete this record?</h1>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            onClick={() => this.handleCloseMeetingDeleteModal(m._id)}
          >
            Close
          </button>
          <button
            type="button"
            class="btn btn-primary"
            onClick={() => this.handleDeleteMeeting(m._id)}
          >
            Delete
          </button>
        </div>
      </div>
    );
  }

  componentDidMount() {
    const { token, setToken } = this.context;

    fetch("https://twem.ca/api/dashboard/", {
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((data) => data.json())
      .then((data) => {
        this.setState({
          clients: data.clients,
        });
      })
      .then(() => this.changeState());

    window.addEventListener("resize", this.changeState);
  }

  currentView() {
    const nav = (
      <nav className="navbar navbar-dark bg-dark">
        <span className="navbar-brand">
          Coaching Relationship Tracking System
        </span>
        <button className="btn btn-outline-info" onClick={this.handleLogout}>
          Logout
        </button>
      </nav>
    );

    switch (this.state.currentView) {
      case "dashboard": {
        const carouselDom = [];
        const clientsLength = this.state.clients.length;
        if (this.state.viewportWidth >= 768) {
          for (let i = 0; i < clientsLength; i += 2) {
            carouselDom.push(
              <div className="container my-3">
                <div className="row align-items-center h-100">
                  <div className="col-6">
                    <button
                      className="btn btn-info w-100"
                      onClick={() => this.handleClientClick(i)}
                    >
                      {this.state.clients[i] &&
                        `${this.state.clients[i].name} -
                       ${this.state.clients[i].project}`}
                    </button>
                    <button
                      className="btn btn-danger"
                      style={{
                        position: "absolute",
                        right: "15px",
                      }}
                      onClick={() => this.handleOpenClientDeleteModal(i)}
                    >
                      <span>×</span>
                    </button>
                    <Modal
                      isOpen={this.state.clients[i].showClientDeleteModal}
                      onRequestClose={() =>
                        this.handleCloseClientDeleteModal(i)
                      }
                      shouldCloseOnOverlayClick={false}
                      className="modal-dialog modal-xl"
                      overlayClassName="modal d-block"
                    >
                      {this.fillClientDeleteModal(i)}
                    </Modal>
                  </div>
                  {i + 1 === clientsLength ? null : (
                    <div className="my-carousel-sub-item col-6">
                      <button
                        className="btn btn-info w-100"
                        onClick={() => this.handleClientClick(i + 1)}
                      >
                        {this.state.clients[i + 1] &&
                          `${this.state.clients[i + 1].name} -
                        ${this.state.clients[i + 1].project}`}
                      </button>
                      <button
                        className="btn btn-danger"
                        style={{
                          position: "absolute",
                          right: "15px",
                        }}
                        onClick={() => this.handleOpenClientDeleteModal(i + 1)}
                      >
                        <span>×</span>
                      </button>
                      <Modal
                        isOpen={this.state.clients[i + 1].showClientDeleteModal}
                        onRequestClose={() =>
                          this.handleCloseClientDeleteModal(i + 1)
                        }
                        shouldCloseOnOverlayClick={false}
                        className="modal-dialog modal-xl"
                        overlayClassName="modal d-block"
                      >
                        {this.fillClientDeleteModal(i + 1)}
                      </Modal>
                    </div>
                  )}
                </div>
              </div>
            );
          }
        } else {
          for (let i = 0; i < clientsLength; i++) {
            carouselDom.push(
              <div className="my-3 position-relative">
                <button
                  className="btn btn-info w-100"
                  onClick={() => this.handleClientClick(i)}
                >
                  {this.state.clients[i] &&
                    `${this.state.clients[i].name} -
                       ${this.state.clients[i].project}`}
                </button>
                <button
                  className="btn btn-danger"
                  style={{
                    position: "absolute",
                    right: "0px",
                  }}
                  onClick={() => this.handleOpenClientDeleteModal(i)}
                >
                  <span>×</span>
                </button>
                <Modal
                  isOpen={this.state.clients[i].showClientDeleteModal}
                  onRequestClose={() => this.handleCloseClientDeleteModal(i)}
                  shouldCloseOnOverlayClick={false}
                  className="modal-dialog modal-xl"
                  overlayClassName="modal d-block"
                >
                  {this.fillClientDeleteModal(i)}
                </Modal>
              </div>
            );
          }
        }

        const toolsDom = this.state.tools.map((tool, i) => (
          <SplideSlide>
            <button
              className="btn btn-success w-100 h-100"
              onClick={() => this.handleOpenToolModal(tool.name)}
            >
              <div>{tool.name}</div>
            </button>
            <Modal
              isOpen={this.state.tools[i].showToolModal}
              onRequestClose={() => this.handleCloseToolModal(tool.name)}
              shouldCloseOnOverlayClick={false}
              className="modal-dialog modal-dialog-scrollable modal-xl"
              overlayClassName="modal d-block"
              style={{ overlay: { zIndex: 50 } }}
            >
              {this.seleteToolModal(tool.name)}
            </Modal>
          </SplideSlide>
        ));

        return (
          <>
            {nav}
            <section className="container">
              <div className="row mt-3">
                <div className="col-8 col-md-4 offset-2">
                  <button
                    className="btn btn-dark w-100"
                    onClick={() => this.changeView("newClient", 1)}
                  >
                    New
                  </button>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-8">{carouselDom}</div>
                <div className="col-12 col-md-4">
                  <Splide
                    options={{
                      direction: "ttb",
                      height: "300px",
                      gap: "1rem",
                      pagination: false,
                      perPage: 3,
                      arrows: false,
                    }}
                  >
                    {toolsDom}
                  </Splide>
                </div>
              </div>
            </section>
          </>
        );
      }
      case "newClient": {
        const subItems = this.state.client.svg.map((d, i) =>
          d !== null ? (
            <div key={i} className="col-2 pb-2 px-1 col-md-3 pd-md-0 col-lg-2">
              {deserialize(d)}
            </div>
          ) : null
        );

        const oldMeetings = this.state.client.meetings.filter(
          (meeting) => new Date(meeting.time) < Date.now()
        );

        const oldMeetingsDom = oldMeetings.map((meeting) => (
          <SplideSlide>
            <button
              className="btn btn-primary w-100 h-100 tool-carousel d-flex justify-content-center align-items-center"
              onClick={() => this.handleOpenMeetingModal(meeting)}
            >
              <div>
                {`${new Date(meeting.time).toLocaleDateString()} - 
                ${new Date(meeting.time).toLocaleTimeString()}`}
              </div>
            </button>
            <button
              className="btn btn-danger"
              style={{
                position: "absolute",
                right: "0",
                bottom: "0",
                top: "0",
              }}
              onClick={() => this.handleOpenMeetingDeleteModal(meeting)}
            >
              <span>×</span>
            </button>
            <Modal
              isOpen={meeting.showMeetingModal}
              onRequestClose={() => this.handleCloseMeetingModal(meeting._id)}
              shouldCloseOnOverlayClick={false}
              className="modal-dialog modal-dialog-scrollable modal-xl"
              overlayClassName="modal d-block"
            >
              {this.fillMeetingModal(meeting, "bg-primary")}
            </Modal>
            <Modal
              isOpen={meeting.showMeetingDeleteModal}
              onRequestClose={() =>
                this.handleCloseMeetingDeleteModal(meeting._id)
              }
              shouldCloseOnOverlayClick={false}
              className="modal-dialog modal-xl"
              overlayClassName="modal d-block"
            >
              {this.fillMeetingDeleteModal(meeting)}
            </Modal>
          </SplideSlide>
        ));

        const newMeetings = this.state.client.meetings.filter(
          (meeting) => new Date(meeting.time) >= Date.now()
        );

        const newMeetingsDom = newMeetings.map((meeting) => (
          <SplideSlide>
            <button
              className="btn btn-primary w-100 h-100 tool-carousel d-flex justify-content-center align-items-center"
              onClick={() => this.handleOpenMeetingModal(meeting)}
            >
              <div>
                {`${new Date(meeting.time).toLocaleDateString()} - 
                ${new Date(meeting.time).toLocaleTimeString()}`}
              </div>
            </button>
            <button
              className="btn btn-danger"
              style={{
                position: "absolute",
                right: "0",
                bottom: "0",
                top: "0",
              }}
              onClick={() => this.handleOpenMeetingDeleteModal(meeting)}
            >
              <span>×</span>
            </button>
            <Modal
              isOpen={meeting.showMeetingModal}
              onRequestClose={() => this.handleCloseMeetingModal(meeting.id)}
              shouldCloseOnOverlayClick={false}
              className="modal-dialog modal-dialog-scrollable modal-xl"
              overlayClassName="modal d-block"
            >
              {this.fillMeetingModal(meeting, "bg-primary")}
            </Modal>
            <Modal
              isOpen={meeting.showMeetingDeleteModal}
              onRequestClose={() =>
                this.handleCloseMeetingDeleteModal(meeting._id)
              }
              shouldCloseOnOverlayClick={false}
              className="modal-dialog modal-xl"
              overlayClassName="modal d-block"
            >
              {this.fillMeetingDeleteModal(meeting)}
            </Modal>
          </SplideSlide>
        ));

        return (
          <>
            {nav}
            <section className="container mt-3">
              <div className="row">
                <div className="col-12">
                  <div class="table-responsive">
                    <table class="table table-bordered table-dark">
                      <thead>
                        <tr>
                          <th scope="col">Name</th>
                          <th scope="col">Email</th>
                          <th scope="col">Contact</th>
                          <th scope="col">Other Contact</th>
                          <th scope="col">Project</th>
                          <th scope="col">First Met Data</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <Input
                              value={this.state.client.name}
                              handleChange={(e) =>
                                this.setState({
                                  client: {
                                    ...this.state.client,
                                    name: e.target.value,
                                  },
                                })
                              }
                              handleDoubleClick={() =>
                                this.setState({
                                  showInputEle: {
                                    ...this.state.showInputEle,
                                    name: true,
                                  },
                                })
                              }
                              handleBlur={() =>
                                this.setState({
                                  showInputEle: {
                                    ...this.state.showInputEle,
                                    name: false,
                                  },
                                })
                              }
                              showInputEle={this.state.showInputEle.name}
                            />
                          </td>
                          <td>
                            <Input
                              value={this.state.client.email}
                              handleChange={(e) =>
                                this.setState({
                                  client: {
                                    ...this.state.client,
                                    email: e.target.value,
                                  },
                                })
                              }
                              handleDoubleClick={() =>
                                this.setState({
                                  showInputEle: {
                                    ...this.state.showInputEle,
                                    email: true,
                                  },
                                })
                              }
                              handleBlur={() =>
                                this.setState({
                                  showInputEle: {
                                    ...this.state.showInputEle,
                                    email: false,
                                  },
                                })
                              }
                              showInputEle={this.state.showInputEle.email}
                            />
                          </td>
                          <td>
                            <Input
                              value={this.state.client.contact}
                              handleChange={(e) =>
                                this.setState({
                                  client: {
                                    ...this.state.client,
                                    contact: e.target.value,
                                  },
                                })
                              }
                              handleDoubleClick={() =>
                                this.setState({
                                  showInputEle: {
                                    ...this.state.showInputEle,
                                    contact: true,
                                  },
                                })
                              }
                              handleBlur={() =>
                                this.setState({
                                  showInputEle: {
                                    ...this.state.showInputEle,
                                    contact: false,
                                  },
                                })
                              }
                              showInputEle={this.state.showInputEle.contact}
                            />
                          </td>
                          <td>
                            <Input
                              value={this.state.client.otherContact}
                              handleChange={(e) =>
                                this.setState({
                                  client: {
                                    ...this.state.client,
                                    otherContact: e.target.value,
                                  },
                                })
                              }
                              handleDoubleClick={() =>
                                this.setState({
                                  showInputEle: {
                                    ...this.state.showInputEle,
                                    otherContact: true,
                                  },
                                })
                              }
                              handleBlur={() =>
                                this.setState({
                                  showInputEle: {
                                    ...this.state.showInputEle,
                                    otherContact: false,
                                  },
                                })
                              }
                              showInputEle={
                                this.state.showInputEle.otherContact
                              }
                            />
                          </td>
                          <td>
                            <Input
                              value={this.state.client.project}
                              handleChange={(e) =>
                                this.setState({
                                  client: {
                                    ...this.state.client,
                                    project: e.target.value,
                                  },
                                })
                              }
                              handleDoubleClick={() =>
                                this.setState({
                                  showInputEle: {
                                    ...this.state.showInputEle,
                                    project: true,
                                  },
                                })
                              }
                              handleBlur={() =>
                                this.setState({
                                  showInputEle: {
                                    ...this.state.showInputEle,
                                    project: false,
                                  },
                                })
                              }
                              showInputEle={this.state.showInputEle.project}
                            />
                          </td>
                          <td>
                            <p>
                              {this.state.client.meetings[0] &&
                                `${new Date(
                                  this.state.client.meetings[0].time
                                ).toLocaleDateString()} - 
                              ${new Date(
                                this.state.client.meetings[0].time
                              ).toLocaleTimeString()}`}
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="6">
                            <Input
                              value={this.state.client.notes}
                              handleChange={(e) =>
                                this.setState({
                                  client: {
                                    ...this.state.client,
                                    notes: e.target.value,
                                  },
                                })
                              }
                              handleDoubleClick={() =>
                                this.setState({
                                  showInputEle: {
                                    ...this.state.showInputEle,
                                    notes: true,
                                  },
                                })
                              }
                              handleBlur={() =>
                                this.setState({
                                  showInputEle: {
                                    ...this.state.showInputEle,
                                    notes: false,
                                  },
                                })
                              }
                              showInputEle={this.state.showInputEle.notes}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <p className="text-left">
                * Single click the table field to edit.
              </p>
              <div className="row">
                <div className="col-6">
                  <button
                    className="btn btn-dark"
                    onClick={() => this.changeView("dashboard")}
                  >
                    Back
                  </button>
                </div>
                <div className="col-6">
                  <button
                    className="btn btn-dark"
                    onClick={this.handleClientInfoChange}
                  >
                    Submit
                  </button>
                </div>
              </div>
              <a
                href={`https://twem.ca/assessment/${this.state.client.assessmentId}`}
                target="_blank"
              >
                <div className="row py-3">{subItems}</div>
              </a>
              <div className="row py-3 justify-content-right">
                <div className="col-12 col-md-2">
                  <Flatpickr
                    options={{
                      enableTime: true,
                      dateFormat: "Y-m-d H:i",
                    }}
                    onChange={([time]) =>
                      this.setState({
                        meeting: { ...this.state.meeting, time },
                      })
                    }
                  />
                  <button
                    className="btn btn-dark mt-2 mb-2 w-100"
                    onClick={this.handleAddMeeting}
                  >
                    Add
                  </button>
                  <p className="text-danger">{this.state.error}</p>
                </div>
                <div className="col-12 col-md-5">
                  <Splide
                    options={{
                      direction: "ttb",
                      height: "300px",
                      gap: "1rem",
                      pagination: false,
                      perPage: 5,
                    }}
                  >
                    {oldMeetingsDom}
                  </Splide>
                </div>
                <div className="col-12 col-md-5">
                  <Splide
                    options={{
                      direction: "ttb",
                      height: "300px",
                      gap: "1rem",
                      pagination: false,
                      perPage: 5,
                    }}
                    className="new"
                  >
                    {newMeetingsDom}
                  </Splide>
                </div>
              </div>
            </section>
          </>
        );
      }
    }
  }

  render() {
    return <>{this.currentView()}</>;
  }
}

export default withRouter(Dashboard);
