import {
  SET_VALUE_F_FN,
  SET_VALUE_F_LN,
  SET_VALUE_F_E,
  SET_VALUE_F_ID,
} from "../actionTypes";

export function setFirstNameValue(val) {
  return {
    type: SET_VALUE_F_FN,
    val,
  };
}

export function setLastNameValue(val) {
  return {
    type: SET_VALUE_F_LN,
    val,
  };
}

export function setEmailValue(val) {
  return {
    type: SET_VALUE_F_E,
    val,
  };
}

export function setIdValue(val) {
  return {
    type: SET_VALUE_F_ID,
    val,
  };
}
