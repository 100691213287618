import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import * as d3 from "d3";
import $ from "jquery";
import { withTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

class SevenFs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      areas: [
        { id: 0, value: 10, area: 1, name: "faith.label" },
        { id: 1, value: 10, area: 1, name: "family.label" },
        { id: 2, value: 10, area: 1, name: "fun.label" },
        { id: 3, value: 10, area: 1, name: "field.label" },
        { id: 4, value: 10, area: 1, name: "fitness.label" },
        { id: 5, value: 10, area: 1, name: "friends.label" },
        { id: 6, value: 10, area: 1, name: "finance.label" },
      ],

      subAreas: [
        {
          id: 0,
          value: 10,
          area: 1,
          name: "faith.option1",
          inputValue: 10,
        },
        {
          id: 1,
          value: 10,
          area: 1,
          name: "faith.option2",
          inputValue: 10,
        },
        {
          id: 2,
          value: 10,
          area: 1,
          name: "faith.option3",
          inputValue: 10,
        },
        {
          id: 3,
          value: 10,
          area: 1,
          name: "family.option1",
          inputValue: 10,
        },
        {
          id: 4,
          value: 10,
          area: 1,
          name: "family.option2",
          inputValue: 10,
        },
        {
          id: 5,
          value: 10,
          area: 1,
          name: "family.option3",
          inputValue: 10,
        },
        {
          id: 6,
          value: 10,
          area: 1,
          name: "fun.option1",
          inputValue: 10,
        },
        {
          id: 7,
          value: 10,
          area: 1,
          name: "fun.option2",
          inputValue: 10,
        },
        {
          id: 8,
          value: 10,
          area: 1,
          name: "fun.option3",
          inputValue: 10,
        },
        {
          id: 9,
          value: 10,
          area: 1,
          name: "field.option1",
          inputValue: 10,
        },
        {
          id: 10,
          value: 10,
          area: 1,
          name: "field.option2",
          inputValue: 10,
        },
        {
          id: 11,
          value: 10,
          area: 1,
          name: "field.option3",
          inputValue: 10,
        },
        {
          id: 12,
          value: 10,
          area: 1,
          name: "fitness.option1",
          inputValue: 10,
        },
        {
          id: 13,
          value: 10,
          area: 1,
          name: "fitness.option2",
          inputValue: 10,
        },
        {
          id: 14,
          value: 10,
          area: 1,
          name: "fitness.option3",
          inputValue: 10,
        },
        {
          id: 15,
          value: 10,
          area: 1,
          name: "friends.option1",
          inputValue: 10,
        },
        {
          id: 16,
          value: 10,
          area: 1,
          name: "friends.option2",
          inputValue: 10,
        },
        {
          id: 17,
          value: 10,
          area: 1,
          name: "friends.option3",
          inputValue: 10,
        },
        {
          id: 18,
          value: 10,
          area: 1,
          name: "finance.option1",
          inputValue: 10,
        },
        {
          id: 19,
          value: 10,
          area: 1,
          name: "finance.option2",
          inputValue: 10,
        },
        {
          id: 20,
          value: 10,
          area: 1,
          name: "finance.option3",
          inputValue: 10,
        },
      ],
      id: "",
      firstName: "",
      lastName: "",
      email: "",
    };

    this.width = 600;
    this.height = 600;
    this.subAreasPerNum = 3;

    this.createPieChart = this.createPieChart.bind(this);
    this.drawGraph = this.drawGraph.bind(this);
    this.processData = this.processData.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.colorScaleAreas = d3
      .scaleOrdinal()
      .domain(this.state.areas.map((a) => a.id))
      .range([
        "#10253f",
        "#1e1c11",
        "#403152",
        "#ffc000",
        "#984807",
        "#254061",
        "#632523",
      ]);

    this.colorScaleSubAreas = d3
      .scaleOrdinal()
      .domain(this.state.subAreas.map((a) => a.id))
      .range([
        "#17375e",
        "#1f497d",
        "#558ed5",
        "#4a452a",
        "#948a54",
        "#c4bd97",
        "#604a7b",
        "#8064a2",
        "#b3a2c7",
        "#eab200",
        "#ffcd2d",
        "#ffdc5d",
        "#e46c0a",
        "#f79646",
        "#fac090",
        "#376092",
        "#4f81bd",
        "#95b3d7",
        "#953735",
        "#c0504d",
        "#d99694",
      ]);
  }

  handleSubmit(e) {
    e.preventDefault();
    const data = this.state.subAreas.map((a) => a.inputValue);
    let method = "";
    let url = "https://twem.ca/api/sevenFs/";
    if (this.state.id) {
      method = "PUT";
      url += this.state.id;
    } else {
      method = "POST";
    }

    fetch(url, {
      method: method,
      headers: new Headers({
        "Content-Type": "application/json",
      }),
      body: JSON.stringify({
        data,
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        email: this.state.email,
        lgn: this.props.i18n.language.slice(0, 2),
      }),
    })
      .then((data) => data.json())
      .then((data) => this.setState({ id: data._id }))
      .then(() => {
        $("body").css("overflow", "hidden");
        $(".ui-dialog").css("position", "fixed");
        $(".ui-dialog").css("display", "block");
      });
  }

  componentDidMount() {
    if (this.props.match.params.id) {
      fetch(`https://twem.ca/api/sevenFs/${this.props.match.params.id}`)
        .then((data) => data.json())
        .then((data) => {
          this.state.subAreas.map((a) =>
            this.processData(
              data.data[a.id],
              Math.floor(a.id / this.subAreasPerNum),
              a.id
            )
          );
          this.setState({
            id: this.props.match.params.id,
            firstName: data.firstName,
            lastName: data.lastName,
            email: data.email,
          });
        });
    }
    this.createPieChart();
  }

  componentDidUpdate() {
    this.drawGraph(this.state.areas, this.state.subAreas);
  }

  createPieChart() {
    const svg = d3
      .select("svg")
      .attr("width", "100%")
      .attr("viewBox", `0 0 ${this.width} ${this.height}`);

    svg
      .append("g")
      .attr("transform", `translate(${this.width / 2}, ${this.height / 2})`)
      .classed("chart", true);

    svg
      .append("g")
      .attr("transform", `translate(${this.width / 2}, ${this.height / 2})`)
      .classed("inner-chart", true);

    this.drawGraph(this.state.areas, this.state.subAreas);
  }

  drawGraph(areas, subAreas) {
    const arcs = d3
      .pie()
      .value((d) => d.area)
      .sort((a, b) => a.id - b.id)(areas);

    const path = d3
      .arc()
      .outerRadius((d) => (d.data.value * this.width) / 20 - 15)
      .innerRadius(0);

    const update = d3.select(".chart").selectAll(".arc").data(arcs);

    update.exit().remove();

    update
      .enter()
      .append("path")
      .classed("arc", true)
      .merge(update)
      .attr("fill", (d) => this.colorScaleAreas(d.data.id))
      .attr("d", path);

    const textPath = d3
      .arc()
      .outerRadius(this.width / 2)
      .innerRadius(this.width / 2);

    update
      .enter()
      .append("text")
      .attr("transform", (d) => {
        if (d.data.id === 2 || d.data.id === 3 || d.data.id === 4) {
          return `translate(${textPath.centroid(d)}) rotate(${
            ((d.startAngle + d.endAngle) * 90) / Math.PI + 180
          })`;
        } else {
          return `translate(${textPath.centroid(d)}) rotate(${
            ((d.startAngle + d.endAngle) * 90) / Math.PI
          })`;
        }
      })
      .attr("dy", (d) =>
        d.data.id === 2 || d.data.id === 3 || d.data.id === 4 ? -4 : 4
      )
      .attr("text-anchor", "middle")
      .attr("alignment-baseline", "middle")
      .style("color", "#000")
      .text((d) => this.props.t(d.data.name));

    const arcsInner = d3
      .pie()
      .value((d) => d.area)
      .sort((a, b) => a.id - b.id)(subAreas);

    const pathInner = d3
      .arc()
      .outerRadius((d) => (d.data.value * this.width) / 20 - 50)
      .innerRadius(0);

    const updateInner = d3
      .select(".inner-chart")
      .selectAll(".arc")
      .data(arcsInner);

    updateInner.exit().remove();

    updateInner
      .enter()
      .append("path")
      .classed("arc", true)
      .merge(updateInner)
      .attr("fill", (d) => this.colorScaleSubAreas(d.data.id))
      .attr("d", pathInner);
  }

  processData(val, areasId, subAreasId) {
    const subAreasVal = (Math.log(val) / Math.log(9.8)) * 10;
    const subAreas = this.state.subAreas.map((a) =>
      a.id === subAreasId
        ? {
            ...a,
            value: subAreasVal > 10 ? 10 : subAreasVal > 3 ? subAreasVal : val,
            inputValue: val,
          }
        : a
    );

    let AreasVal = 0;
    if (subAreasId % this.subAreasPerNum === 0)
      AreasVal =
        (val +
          this.state.subAreas[subAreasId + 1].value +
          this.state.subAreas[subAreasId + 2].value) /
        this.subAreasPerNum;
    else if (subAreasId % this.subAreasPerNum === 1)
      AreasVal =
        (this.state.subAreas[subAreasId - 1].value +
          val +
          this.state.subAreas[subAreasId + 1].value) /
        this.subAreasPerNum;
    else if (subAreasId % this.subAreasPerNum === 2)
      AreasVal =
        (this.state.subAreas[subAreasId - 2].value +
          this.state.subAreas[subAreasId - 1].value +
          val) /
        this.subAreasPerNum;
    AreasVal = (Math.log(AreasVal) / Math.log(9.8)) * 10;
    const areas = this.state.areas.map((a) =>
      a.id === areasId ? { ...a, value: AreasVal > 10 ? 10 : AreasVal } : a
    );

    this.setState({ areas, subAreas });
  }

  render() {
    const elements = this.state.subAreas.map((a) => {
      if (a.id % this.subAreasPerNum === 0)
        return (
          <tr key={a.id}>
            <th
              rowSpan={this.subAreasPerNum}
              style={{
                backgroundColor: this.colorScaleAreas(
                  Math.floor(a.id / this.subAreasPerNum)
                ),
              }}
            >
              {this.props.t(
                this.state.areas[Math.floor(a.id / this.subAreasPerNum)].name
              )}
            </th>
            <td
              style={{
                backgroundColor: this.colorScaleSubAreas(a.id),
                padding: "0.3rem",
              }}
            >
              {this.props.t(a.name)}
            </td>
            <td style={{ padding: "0.3rem" }}>
              <input
                type="range"
                min="0"
                max="10"
                value={a.inputValue}
                onChange={(e) =>
                  this.processData(
                    +e.target.value,
                    Math.floor(a.id / this.subAreasPerNum),
                    a.id
                  )
                }
              />
            </td>
            <td style={{ padding: "0.3rem" }}>
              <span>{a.inputValue}</span>
            </td>
          </tr>
        );
      else
        return (
          <tr key={a.id}>
            <td
              style={{
                backgroundColor: this.colorScaleSubAreas(a.id),
                padding: "0.3rem",
              }}
            >
              {this.props.t(a.name)}
            </td>
            <td style={{ padding: "0.3rem" }}>
              <input
                type="range"
                min="0"
                max="10"
                value={a.inputValue}
                onChange={(e) =>
                  this.processData(
                    +e.target.value,
                    Math.floor(a.id / this.subAreasPerNum),
                    a.id
                  )
                }
              />
            </td>
            <td style={{ padding: "0.3rem" }}>
              <span>{a.inputValue}</span>
            </td>
          </tr>
        );
    });

    const form = (
      <form className="mt-5" onSubmit={(e) => this.handleSubmit(e)}>
        <div className="form-row">
          <div className="col-md-6 mb-3 text-left">
            <label htmlFor="first-name">
              {this.props.t("firstname.label")}
            </label>
            <input
              type="text"
              className="form-control"
              id="first-name"
              value={this.state.firstName}
              onChange={(e) => this.setState({ firstName: e.target.value })}
              required
            />
          </div>
          <div className="col-md-6 mb-3 text-left">
            <label htmlFor="last-name">{this.props.t("lastname.label")}</label>
            <input
              type="text"
              className="form-control"
              id="last-name"
              value={this.state.lastName}
              onChange={(e) => this.setState({ lastName: e.target.value })}
              required
            />
          </div>
        </div>
        <div className="form-group text-left">
          <label htmlFor="email">{this.props.t("email.label")}</label>
          <input
            type="email"
            className="form-control"
            id="email"
            aria-describedby="emailHelp"
            value={this.state.email}
            onChange={(e) => this.setState({ email: e.target.value })}
            required
          />
          <small id="emailHelp" className="form-text text-muted">
            {this.props.t("email.prompt1")}
          </small>
        </div>
        <button type="submit" className="btn btn-dark float-right mb-3">
          {this.props.t("submit.label")}
        </button>
      </form>
    );

    return (
      <div className="container">
        <Helmet>
          <title>THNQ 7Fs</title>
          <meta name="description" content="How is your balance today?" />

          {/* <!-- Facebook Meta Tags --> */}
          <meta property="og:url" content="https://twem.ca/7fs" />
          <meta property="og:type" content="website" />
          <meta property="og:title" content="THNQ 7Fs" />
          <meta
            property="og:description"
            content="How is your balance today?"
          />
          <meta property="og:image" content="" />

          {/* <!-- Twitter Meta Tags --> */}
          <meta name="twitter:card" content="summary_large_image" />
          <meta property="twitter:domain" content="twem.ca" />
          <meta property="twitter:url" content="https://twem.ca/7fs" />
          <meta name="twitter:title" content="THNQ 7Fs" />
          <meta
            name="twitter:description"
            content="How is your balance today?"
          />
          <meta name="twitter:image" content="" />
        </Helmet>
        <h1>The 7F's</h1>
        <p>
          {this.props.t("title.label1")}
          <strong> {this.props.t("title.label2")} </strong>
          {this.props.t("title.label3")}
        </p>
        <div className="row">
          <div className="col-md-6">
            <svg
              version="1.1"
              baseProfile="full"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="xMinYMin meet"
            ></svg>
            <div className="d-none d-md-block">{form}</div>
          </div>
          <div className="col-md-6 mt-5 mt-md-0">
            <p className="d-block d-md-none mb-3 mb-md-0">
              Please enter your email address at the end to receive a report
            </p>
            <table className="table table-dark">
              <thead>
                <tr>
                  <th>{this.props.t("area.label")}</th>
                  <th>{this.props.t("element.label")}</th>
                  <th colSpan="2">{this.props.t("value.label")}</th>
                </tr>
              </thead>
              <tbody>{elements}</tbody>
            </table>
            <div className="d-block d-md-none">{form}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(withTranslation()(SevenFs));
