import { SET_OPACITY_IE, SET_VALUE_IE } from "../actionTypes";

export function setOpacity(ie) {
  return {
    type: SET_OPACITY_IE,
    ie,
  };
}

export function setValue(val) {
  return {
    type: SET_VALUE_IE,
    val,
  };
}
