import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { configureStore } from "./store";
import "./index.css";
import App from "./App";
import Thankyou from "./components/Thankyou";
import * as serviceWorker from "./serviceWorker";
import "./i18n";
import { UserProvider } from "./components/UserContext";

const store = configureStore();

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Suspense fallback="Loading...">
        <UserProvider>
          <App />
        </UserProvider>
        <Thankyou />
      </Suspense>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
