import { SET_OPACITY_PS } from "../actionTypes";

const DEFAULT_STATE = {
  AC: 0.3,
  AD: 0.3,
  BD: 0.3,
  BC: 0.3,
};

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case SET_OPACITY_PS:
      return { ...DEFAULT_STATE, [action.ps]: 1 };
    default:
      return state;
  }
};
