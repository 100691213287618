import { SET_VALUE_VI } from "../actionTypes";

export function setValue(val, itemIdx, questionIdx) {
  return {
    type: SET_VALUE_VI,
    val,
    itemIdx,
    questionIdx,
  };
}
