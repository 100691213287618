export const GET_ASSESSMENT = "GET_ASSESSMENT";
export const SET_SVG = "SET_SVG";
export const SET_OPACITY_PS = "SET_OPACITY_PS";
export const SET_OPACITY_IE = "SET_OPACITY_IE";
export const SET_VALUE_IE = "SET_VALUE_IE";
export const SET_GRADE_TH = "SET_GRADE_TH";
export const SET_VALUE_IH = "SET_VALUE_IH";
export const SET_VALUE_GS = "SET_VALUE_GS";
export const SET_VALUE_ESA = "SET_VALUE_ESA";
export const SET_VALUE_VI = "SET_VALUE_VI";
export const SET_VALUE_S = "SET_VALUE_S";
export const SET_VALUE_D = "SET_VALUE_D";
export const SET_VALUE_SN = "SET_VALUE_SN";
export const SET_VALUE_F_FN = "SET_VALUE_F_FN";
export const SET_VALUE_F_LN = "SET_VALUE_F_LN";
export const SET_VALUE_F_E = "SET_VALUE_F_E";
export const SET_VALUE_F_ID = "SET_VALUE_F_ID";
