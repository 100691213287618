import React, { Component } from "react";
import $ from "jquery";
import "jquery-ui-dist/jquery-ui";
import { withTranslation } from "react-i18next";

class Thankyou extends Component {
  componentDidMount() {
    $("#dialog").dialog();
    $(".ui-dialog").css("display", "none");
    $(".ui-dialog").addClass("alert alert-primary");
    $(".ui-icon-closethick").click(() => {
      $(".ui-dialog").css("display", "none");
      $(".ui-dialog").css("position", "");
      $("body").css("overflow", "");
    });
  }

  render() {
    return (
      <div
        id="dialog"
        title={this.props.t("thankyou.title")}
        style={{ display: "none" }}
      >
        <p>{this.props.t("thankyou.label1")}</p>
        <p>
          {this.props.t("thankyou.label2")}&nbsp;
          <a
            className="alert-link"
            href="https://www.PocketMentor.ca/"
            target="_blank"
            rel="noopener noreferrer"
          >
            PocketMentor.ca
          </a>
        </p>
      </div>
    );
  }
}

export default withTranslation()(Thankyou);
