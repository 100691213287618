import { SET_VALUE_ESA } from "../actionTypes";

const DEFAULT_STATE = {
  areas: [
    { value: 7.374621015652228 },
    { value: 7.374621015652228 },
    { value: 7.374621015652228 },
    { value: 7.374621015652228 },
    { value: 7.374621015652228 },
  ],

  subAreas: [
    {
      value: 6.073891780009404,
      subQuestions: [
        { value: 6.073891780009404, checkedValue: 4 },
        { value: 6.073891780009404, checkedValue: 4 },
      ],
    },
    {
      value: 6.073891780009404,
      subQuestions: [
        { value: 6.073891780009404, checkedValue: 4 },
        { value: 6.073891780009404, checkedValue: 4 },
      ],
    },
    {
      value: 6.073891780009404,
      subQuestions: [
        { value: 6.073891780009404, checkedValue: 4 },
        { value: 6.073891780009404, checkedValue: 4 },
      ],
    },
    {
      value: 6.073891780009404,
      subQuestions: [
        { value: 6.073891780009404, checkedValue: 4 },
        { value: 6.073891780009404, checkedValue: 4 },
      ],
    },
    {
      value: 6.073891780009404,
      subQuestions: [
        { value: 6.073891780009404, checkedValue: 4 },
        { value: 6.073891780009404, checkedValue: 4 },
      ],
    },
    {
      value: 6.073891780009404,
      subQuestions: [
        { value: 6.073891780009404, checkedValue: 4 },
        { value: 6.073891780009404, checkedValue: 4 },
      ],
    },
    {
      value: 6.073891780009404,
      subQuestions: [
        { value: 6.073891780009404, checkedValue: 4 },
        { value: 6.073891780009404, checkedValue: 4 },
      ],
    },
    {
      value: 6.073891780009404,
      subQuestions: [
        { value: 6.073891780009404, checkedValue: 4 },
        { value: 6.073891780009404, checkedValue: 4 },
      ],
    },
    {
      value: 6.073891780009404,
      subQuestions: [
        { value: 6.073891780009404, checkedValue: 4 },
        { value: 6.073891780009404, checkedValue: 4 },
      ],
    },
    {
      value: 6.073891780009404,
      subQuestions: [
        { value: 6.073891780009404, checkedValue: 4 },
        { value: 6.073891780009404, checkedValue: 4 },
      ],
    },
    {
      value: 6.073891780009404,
      subQuestions: [
        { value: 6.073891780009404, checkedValue: 4 },
        { value: 6.073891780009404, checkedValue: 4 },
      ],
    },
    {
      value: 6.073891780009404,
      subQuestions: [
        { value: 6.073891780009404, checkedValue: 4 },
        { value: 6.073891780009404, checkedValue: 4 },
      ],
    },
    {
      value: 6.073891780009404,
      subQuestions: [
        { value: 6.073891780009404, checkedValue: 4 },
        { value: 6.073891780009404, checkedValue: 4 },
      ],
    },
    {
      value: 6.073891780009404,
      subQuestions: [
        { value: 6.073891780009404, checkedValue: 4 },
        { value: 6.073891780009404, checkedValue: 4 },
      ],
    },
    {
      value: 6.073891780009404,
      subQuestions: [
        { value: 6.073891780009404, checkedValue: 4 },
        { value: 6.073891780009404, checkedValue: 4 },
      ],
    },
  ],
};

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case SET_VALUE_ESA:
      const subQuestions = [...state.subAreas[action.subAreasId].subQuestions];
      subQuestions[action.subQuestionId].value =
        (Math.log(action.val) / Math.log(9.8)) * 10;
      subQuestions[action.subQuestionId].checkedValue = action.val;

      const subAreasVal = (subQuestions[0].value + subQuestions[1].value) / 2;
      const subAreas = state.subAreas.map((a, i) =>
        i === action.subAreasId
          ? {
              ...a,
              value:
                subAreasVal > 10
                  ? 10
                  : subAreasVal > 3
                  ? subAreasVal
                  : action.val,
              subQuestions,
            }
          : a
      );

      let AreasVal = 0;
      if (action.subAreasId % action.subAreasPerNum === 0)
        AreasVal =
          (action.val +
            state.subAreas[action.subAreasId + 1].value +
            state.subAreas[action.subAreasId + 2].value) /
          action.subAreasPerNum;
      else if (action.subAreasId % action.subAreasPerNum === 1)
        AreasVal =
          (state.subAreas[action.subAreasId - 1].value +
            action.val +
            state.subAreas[action.subAreasId + 1].value) /
          action.subAreasPerNum;
      else if (action.subAreasId % action.subAreasPerNum === 2)
        AreasVal =
          (state.subAreas[action.subAreasId - 2].value +
            state.subAreas[action.subAreasId - 1].value +
            action.val) /
          action.subAreasPerNum;
      AreasVal = (Math.log(AreasVal) / Math.log(9.8)) * 10;
      const areas = state.areas.map((a, i) =>
        i === Math.floor(action.subAreasId / action.subAreasPerNum)
          ? { ...a, value: AreasVal > 10 ? 10 : AreasVal }
          : a
      );

      return { areas, subAreas };
    default:
      return state;
  }
};
