import React, { Component } from "react";
import * as d3 from "d3";
import $ from "jquery";
import { withTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

class LeadershipMatrix extends Component {
  constructor(props) {
    super(props);
    this.state = {
      roles: [
        {
          id: 0,
          name: "visionary.label",
          subValues: [3, 3, 3],
          value: 9,
          personName: "enterName.label",
          isEditable: false,
        },
        {
          id: 1,
          name: "operationsImplementer.label",
          subValues: [3, 3, 3],
          value: 9,
          personName: "enterName.label",
          isEditable: false,
        },
        {
          id: 2,
          name: "financialExpertise.label",
          subValues: [3, 3, 3],
          value: 9,
          personName: "enterName.label",
          isEditable: false,
        },
        {
          id: 3,
          name: "salesMarketing.label",
          subValues: [3, 3, 3],
          value: 9,
          personName: "enterName.label",
          isEditable: false,
        },
        {
          id: 4,
          name: "cultureHiring.label",
          subValues: [3, 3, 3],
          value: 9,
          personName: "enterName.label",
          isEditable: false,
        },
        {
          id: 5,
          name: "technology.label",
          subValues: [3, 3, 3],
          value: 9,
          personName: "enterName.label",
          isEditable: false,
        },
        {
          id: 6,
          name: "marketIndustryExperience.label",
          subValues: [3, 3, 3],
          value: 9,
          personName: "enterName.label",
          isEditable: false,
        },
        {
          id: 7,
          name: "mentor.label",
          subValues: [3, 3, 3],
          value: 9,
          personName: "enterName.label",
          isEditable: false,
        },
      ],
      experienceValue: 100,
      team: { subValues: [3, 3, 3, 3], value: 8, impactValue: 100 },
      score: 96,
      id: "",
      firstName: "",
      lastName: "",
      email: "",
    };

    this.width = 600;
    this.height = 600;
    this.radius = 280;

    this.createRadarChart = this.createRadarChart.bind(this);
    this.drawGraph = this.drawGraph.bind(this);
    this.calcPolygons = this.calcPolygons.bind(this);
    this.calcData = this.calcData.bind(this);
    this.handleChangeRoles = this.handleChangeRoles.bind(this);
    this.handleChangeTeam = this.handleChangeTeam.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleInput = this.handleInput.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    if (this.props.match.params.id) {
      fetch(
        `https://twem.ca/api/leadershipMatrix/${this.props.match.params.id}`
      )
        .then((data) => data.json())
        .then((data) => {
          this.state.roles.map((a) => {
            a.subValues.map((_, i) =>
              this.handleChangeRoles(data.roles[a.id].subValues[i], a.id, i)
            );
            return this.handleInput(data.roles[a.id].personName, a.id);
          });
          this.state.team.subValues.map((_, i) =>
            this.handleChangeTeam(data.team[i], i)
          );
          this.setState({
            id: this.props.match.params.id,
            firstName: data.firstName,
            lastName: data.lastName,
            email: data.email,
          });
        });
    }
    this.createRadarChart();
  }

  componentDidUpdate() {
    this.drawGraph();
  }

  createRadarChart() {
    d3.select("svg")
      .attr("width", "100%")
      .attr("viewBox", `0 0 ${this.width} ${this.height}`)
      .append("g")
      .attr(
        "transform",
        `translate(${this.width / 2}, ${this.height / 2}) rotate(180)`
      )
      .classed("chart", true);

    d3.select(".chart")
      .selectAll(".label")
      .data(this.state.roles)
      .enter()
      .append("text")
      .classed("label", true)
      .attr("transform", (d) => {
        let angle = 0;
        switch (d.id) {
          case 0:
            angle = 180;
            break;
          case 1:
          case 3:
            angle = 90;
            break;
          case 5:
          case 7:
            angle = 270;
            break;
          default:
            break;
        }

        return `translate(${
          this.radius * Math.sin((d.id * Math.PI * 2) / this.state.roles.length)
        }, ${
          this.radius * Math.cos((d.id * Math.PI * 2) / this.state.roles.length)
        }) rotate(${(d.id * 360) / this.state.roles.length + angle})`;
      })
      .attr("dy", (d) => (d.id === 3 || d.id === 4 || d.id === 5 ? 10 : -10))
      .attr("text-anchor", "middle")
      .attr("alignment-baseline", "middle")
      .text((d) => this.props.t(d.name));

    this.drawGraph();
  }

  drawGraph() {
    const polygons = this.calcPolygons();

    const edges = d3.select(".chart").selectAll(".edge").data(polygons);

    edges.exit().remove();

    edges
      .enter()
      .append("polygon")
      .classed("edge", true)
      .merge(edges)
      .attr("points", (d) => d)
      .attr("fill", (_, i) => (i % 2 === 0 ? "white" : "#ddd"))
      .attr("stroke", "gray");

    const diagonalLines = d3
      .select(".chart")
      .selectAll(".line")
      .data(polygons[0]);

    diagonalLines.exit().remove();

    diagonalLines
      .enter()
      .append("line")
      .classed("line", true)
      .merge(diagonalLines)
      .attr("x1", 0)
      .attr("y1", 0)
      .attr("x2", (d) => +d.split(",")[0])
      .attr("y2", (d) => +d.split(",")[1])
      .attr("stroke", "#9E1F63");

    const data = d3.select(".chart").selectAll(".data").data(this.calcData());

    data.exit().remove();

    data
      .enter()
      .append("polygon")
      .classed("data", true)
      .merge(data)
      .attr("points", (d) => d)
      .attr("fill", "none")
      .attr("stroke", "#9E1F63")
      .attr("stroke-width", 4);
  }

  handleChangeRoles(val, i, j) {
    const subValues = [...this.state.roles[i].subValues];
    subValues[j] = val;
    const value = d3.sum(subValues);
    const roles = this.state.roles.map((a) =>
      a.id === i ? { ...this.state.roles[a.id], subValues, value } : a
    );
    const rolesSum = d3.sum(
      roles.map((a) => (a.id === 6 || a.id === 7 ? a.value * 2 : a.value))
    );
    const experienceValue = Math.round((rolesSum * 10) / 9);
    const score = Math.round((rolesSum * this.state.team.value) / 7.5);
    this.setState({ roles, experienceValue, score });
  }

  calcPolygons() {
    const polygons = [];

    const radian = (Math.PI * 2) / this.state.roles.length;
    const radiusStep =
      this.radius / d3.max(this.state.roles.map((i) => i.value));

    for (let r = this.radius; r > 0; r -= radiusStep) {
      let vertexs = [];

      for (let i = 0; i < this.state.roles.length; i++) {
        vertexs.push(
          `${Math.sin(i * radian) * r}, ${Math.cos(i * radian) * r}`
        );
      }

      polygons.push(vertexs);
    }
    return polygons;
  }

  calcData() {
    let polygon = [];
    let vertexs = [];

    const radian = (Math.PI * 2) / this.state.roles.length;
    const radiusStep =
      this.radius / d3.max(this.state.roles.map((i) => i.value));

    for (let i = 0; i < this.state.roles.length; i++) {
      vertexs.push(
        `${Math.sin(i * radian) * this.state.roles[i].value * radiusStep}, ${
          Math.cos(i * radian) * this.state.roles[i].value * radiusStep
        }`
      );
    }
    polygon.push(vertexs);
    return polygon;
  }

  handleChangeTeam(val, i) {
    const subValues = [...this.state.team.subValues];
    subValues[i] = val;
    const value = d3.sum(subValues.map((a) => 0.5 * (a + 1)));
    const impactValue = Math.round((value * 100) / 8);
    const score = Math.round(
      (d3.sum(
        this.state.roles.map((a) =>
          a.id === 6 || a.id === 7 ? a.value * 2 : a.value
        )
      ) *
        value) /
        7.5
    );
    this.setState({ team: { subValues, value, impactValue }, score });
  }

  handleInput(val, i) {
    const roles = this.state.roles.map((d) =>
      d.id === i ? { ...this.state.roles[i], personName: val } : d
    );
    this.setState({ roles });
  }

  handleEdit(i, editable) {
    const roles = this.state.roles.map((d) =>
      d.id === i ? { ...this.state.roles[i], isEditable: editable } : d
    );
    this.setState({ roles });
  }

  handleSubmit(e) {
    e.preventDefault();
    const data = this.state.roles.map((a) => ({
      subValues: a.subValues,
      personName: a.personName,
    }));
    let method = "";
    let url = "https://twem.ca/api/leadershipMatrix/";
    if (this.state.id) {
      method = "PUT";
      url += this.state.id;
    } else {
      method = "POST";
    }

    fetch(url, {
      method: method,
      headers: new Headers({
        "Content-Type": "application/json",
      }),
      body: JSON.stringify({
        roles: data,
        team: this.state.team.subValues,
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        email: this.state.email,
      }),
    })
      .then((data) => data.json())
      .then((data) => this.setState({ id: data._id }))
      .then(() => {
        $("body").css("overflow", "hidden");
        $(".ui-dialog").css("position", "fixed");
        $(".ui-dialog").css("display", "block");
      });
  }

  render() {
    const elements = this.state.roles.map((d) => {
      const tds = [];
      for (let i = 0; i < d.subValues.length; i++) {
        tds.push(
          <td key={i} rowSpan="2" style={{ verticalAlign: "middle" }}>
            <select
              value={d.subValues[i]}
              onChange={(e) => this.handleChangeRoles(+e.target.value, d.id, i)}
            >
              <option value="0">{this.props.t("none.label")}</option>
              <option value="1">{this.props.t("some.label")}</option>
              <option value="2">{this.props.t("average.label")}</option>
              <option value="3">{this.props.t("exceptional.label")}</option>
            </select>
          </td>
        );
      }

      const name = d.isEditable ? (
        <td style={{ padding: "0.2rem" }}>
          <input
            onChange={(e) => this.handleInput(e.target.value, d.id)}
            onBlur={() => this.handleEdit(d.id, false)}
            size="16"
            autoFocus
          />
        </td>
      ) : (
        <td
          style={{ padding: "0.3rem" }}
          onClick={() => this.handleEdit(d.id, true)}
        >
          {this.props.t(d.personName)}
        </td>
      );

      return (
        <React.Fragment key={d.id}>
          <tr className={d.id === 6 || d.id === 7 ? "table-secondary" : ""}>
            <th style={{ padding: "0.3rem" }}>{this.props.t(d.name)}</th>
            {tds}
            <td rowSpan="2">
              {d.id === 6 || d.id === 7 ? d.value * 2 : d.value}
            </td>
          </tr>
          <tr className={d.id === 6 || d.id === 7 ? "table-secondary" : ""}>
            {name}
          </tr>
        </React.Fragment>
      );
    });

    const team = this.state.team.subValues.map((_, i) => {
      return (
        <td key={i}>
          <select
            value={this.state.team.subValues[i]}
            onChange={(e) => this.handleChangeTeam(+e.target.value, i)}
          >
            <option value="1">{this.props.t("little.label")}</option>
            <option value="2">{this.props.t("some.label")}</option>
            <option value="3">{this.props.t("lots.label")}</option>
          </select>
        </td>
      );
    });

    const form = (
      <form className="mt-5" onSubmit={(e) => this.handleSubmit(e)}>
        <div className="form-row">
          <div className="col-md-6 mb-3 text-left">
            <label htmlFor="first-name">
              {this.props.t("firstname.label")}
            </label>
            <input
              type="text"
              className="form-control"
              id="first-name"
              value={this.state.firstName}
              onChange={(e) => this.setState({ firstName: e.target.value })}
              required
            />
          </div>
          <div className="col-md-6 mb-3 text-left">
            <label htmlFor="last-name">{this.props.t("lastname.label")}</label>
            <input
              type="text"
              className="form-control"
              id="last-name"
              value={this.state.lastName}
              onChange={(e) => this.setState({ lastName: e.target.value })}
              required
            />
          </div>
        </div>
        <div className="form-group text-left">
          <label htmlFor="email">{this.props.t("email.label")}</label>
          <input
            type="email"
            className="form-control"
            id="email"
            aria-describedby="emailHelp"
            value={this.state.email}
            onChange={(e) => this.setState({ email: e.target.value })}
            required
          />
          <small id="emailHelp" className="form-text text-muted">
            {this.props.t("email.prompt1")}
          </small>
        </div>
        <button type="submit" className="btn btn-dark float-right mb-3">
          {this.props.t("submit.label")}
        </button>
      </form>
    );

    return (
      <div className="container">
        <Helmet>
          <title>THNQ Leadership Matrix</title>
          <meta name="description" content="Rate your leadership team" />

          {/* <!-- Facebook Meta Tags --> */}
          <meta property="og:url" content="https://twem.ca/leadershipmatrix" />
          <meta property="og:type" content="website" />
          <meta property="og:title" content="THNQ Leadership Matrix" />
          <meta property="og:description" content="Rate your leadership team" />
          <meta property="og:image" content="" />

          {/* <!-- Twitter Meta Tags --> */}
          <meta name="twitter:card" content="summary_large_image" />
          <meta property="twitter:domain" content="twem.ca" />
          <meta
            property="twitter:url"
            content="https://twem.ca/leadershipmatrix"
          />
          <meta name="twitter:title" content="THNQ Leadership Matrix" />
          <meta
            name="twitter:description"
            content="Rate your leadership team"
          />
          <meta name="twitter:image" content="" />
        </Helmet>
        <h1>{this.props.t("leadership.label")}</h1>
        <p>{this.props.t("leadership.desc")}</p>
        <div className="row">
          <div className="col-md-6">
            <svg
              version="1.1"
              baseProfile="full"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="xMinYMin meet"
            ></svg>
            <h2 style={{ color: "#9E1F63" }}>
              {this.state.score}% : {this.props.t("leadership.score")}
            </h2>
            <div class="d-none d-md-block">{form}</div>
          </div>
          <div className="col-md-6 mt-5 mt-md-0">
            <p class="d-block d-md-none mb-3 mb-md-0">
              {this.props.t("email.prompt2")}
            </p>
            <table className="table" style={{ fontSize: "0.8rem" }}>
              <thead className="thead-dark">
                <tr>
                  <th>
                    <svg
                      version="1.1"
                      baseProfile="full"
                      xmlns="http://www.w3.org/2000/svg"
                      preserveAspectRatio="xMinYMin meet"
                      viewBox="0 0 140 70"
                    >
                      <line
                        x1="0"
                        y1="0"
                        x2="100%"
                        y2="100%"
                        stroke="white"
                        strokeWidth="2"
                      />
                      <text fill="white" textAnchor="middle" x="30%" y="80%">
                        {this.props.t("role.label")}
                      </text>
                      <text fill="white" textAnchor="middle" x="60%" y="15%">
                        {this.props.t("leadername.label")}
                      </text>
                    </svg>
                  </th>
                  <th>{this.props.t("leadership.question1")}</th>
                  <th>{this.props.t("leadership.question2")}</th>
                  <th>{this.props.t("leadership.question3")}</th>
                  <th>{this.props.t("total.label")}</th>
                </tr>
              </thead>
              <tbody>
                {elements}
                <tr style={{ fontWeight: "bold" }}>
                  <th></th>
                  <td colSpan="2"></td>
                  <td className="table-secondary">
                    {this.props.t("experience.label")}:
                  </td>
                  <td className="table-secondary">
                    {this.state.experienceValue}%
                  </td>
                </tr>
              </tbody>
            </table>
            <table className="table" style={{ fontSize: "0.95rem" }}>
              <thead className="thead-dark">
                <tr>
                  <th>{this.props.t("impact.label")}</th>
                  <th>{this.props.t("leadership.question4")}</th>
                  <th>{this.props.t("leadership.question5")}</th>
                  <th>{this.props.t("leadership.question6")}</th>
                  <th>{this.props.t("leadership.question7")}</th>
                  <th>{this.props.t("total.label")}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>{this.props.t("rate.label")}</th>
                  {team}
                  <td className="table-secondary">{this.state.team.value}</td>
                </tr>
                <tr style={{ fontWeight: "bold" }}>
                  <th></th>
                  <td colSpan="3"></td>
                  <td className="table-secondary">
                    {this.props.t("impactMulti.label")}
                  </td>
                  <td className="table-secondary">
                    {this.state.team.impactValue}%
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="d-block d-md-none">{form}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(LeadershipMatrix);
