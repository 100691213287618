import React, { useState } from "react";

const UserContext = React.createContext({ token: "", setToken: () => {} });

const UserProvider = (props) => {
  const [token, setToken] = useState("");
  const value = { token, setToken };

  return (
    <UserContext.Provider value={value}>{props.children}</UserContext.Provider>
  );
};

export { UserContext, UserProvider };
