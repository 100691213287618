import { SET_VALUE_ESA } from "../actionTypes";

export function setValue(val, subAreasId, subQuestionId, subAreasPerNum) {
  return {
    type: SET_VALUE_ESA,
    val,
    subAreasId,
    subQuestionId,
    subAreasPerNum,
  };
}
