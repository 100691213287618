import { SET_VALUE_VI } from "../actionTypes";

const DEFAULT_STATE = {
  items: [
    {
      questions: [{ inputValue: 3 }, { inputValue: 3 }, { inputValue: 3 }],
      value: 100,
    },
    {
      questions: [{ inputValue: 3 }, { inputValue: 3 }, { inputValue: 3 }],
      value: 100,
    },
  ],
};

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case SET_VALUE_VI:
      const questions = state.items[action.itemIdx].questions.map((q, i) =>
        i === action.questionIdx ? { ...q, inputValue: action.val } : q
      );
      const items = state.items.map((e, i) =>
        i === action.itemIdx
          ? {
              ...e,
              value:
                questions.map((v) => v.inputValue).reduce((a, b) => a + b, 0) /
                0.09,
              questions,
            }
          : e
      );
      return { items };
    default:
      return state;
  }
};
