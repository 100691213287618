import {
  SET_VALUE_F_FN,
  SET_VALUE_F_LN,
  SET_VALUE_F_E,
  SET_VALUE_F_ID,
} from "../actionTypes";

const DEFAULT_STATE = {
  firstName: "",
  lastName: "",
  email: "",
  id: "",
};

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case SET_VALUE_F_FN:
      return { ...state, firstName: action.val };
    case SET_VALUE_F_LN:
      return { ...state, lastName: action.val };
    case SET_VALUE_F_E:
      return { ...state, email: action.val };
    case SET_VALUE_F_ID:
      return { ...state, id: action.val };
    default:
      return state;
  }
};
