import React from "react";
import { connect } from "react-redux";
import { setValue } from "../store/actions/scenarios";
import { withTranslation } from "react-i18next";

function Scenarios(props) {
  const svg = (
    <svg
      version="1.1"
      baseProfile="full"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMinYMin meet"
      viewBox="0 0 200 200"
    >
      <rect x="0" y="0" width="200" height="200" fill="#7952b3"></rect>
      <text
        x="100"
        y="100"
        textAnchor="middle"
        alignmentBaseline="middle"
        fontSize="5rem"
        fill="#fff"
      >
        S
      </text>
    </svg>
  );
  return (
    <div>
      <h3>{props.t("scenarios.question")}</h3>
      <p>{props.t("scenarios.desc")}</p>
      <table className="table table-striped table-bordered text-left">
        <thead className="thead-dark">
          <tr>
            <th>{props.t("questions")}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>{props.t("scenarios.question1")}</th>
          </tr>
          <tr>
            <td style={{ padding: "0.2rem" }}>
              <textarea
                rows="2"
                cols="400"
                style={{ width: "100%", resize: "none", border: "none" }}
                value={props.text[0]}
                onChange={(e) => props.setValue(e.target.value, 0)}
              />
            </td>
          </tr>
          <tr>
            <th>{props.t("scenarios.question2")}</th>
          </tr>
          <tr>
            <td style={{ padding: "0.2rem" }}>
              <textarea
                rows="2"
                cols="400"
                style={{ width: "100%", resize: "none", border: "none" }}
                value={props.text[1]}
                onChange={(e) => props.setValue(e.target.value, 1)}
              />
            </td>
          </tr>
          <tr>
            <th>{props.t("scenarios.question3")}</th>
          </tr>
          <tr>
            <td style={{ padding: "0.2rem" }}>
              <textarea
                rows="2"
                cols="400"
                style={{ width: "100%", resize: "none", border: "none" }}
                value={props.text[2]}
                onChange={(e) => props.setValue(e.target.value, 2)}
              />
            </td>
          </tr>
          <tr>
            <th>{props.t("scenarios.question4")}</th>
          </tr>
          <tr>
            <td style={{ padding: "0.2rem" }}>
              <textarea
                rows="2"
                cols="400"
                style={{ width: "100%", resize: "none", border: "none" }}
                value={props.text[3]}
                onChange={(e) => props.setValue(e.target.value, 3)}
              />
            </td>
          </tr>
          <tr>
            <th>{props.t("scenarios.question5")}</th>
          </tr>
          <tr>
            <td style={{ padding: "0.2rem" }}>
              <textarea
                rows="2"
                cols="400"
                style={{ width: "100%", resize: "none", border: "none" }}
                value={props.text[4]}
                onChange={(e) => props.setValue(e.target.value, 4)}
              />
            </td>
          </tr>
          <tr>
            <th>{props.t("scenarios.question6")}</th>
          </tr>
          <tr>
            <td style={{ padding: "0.2rem" }}>
              <textarea
                rows="2"
                cols="400"
                style={{ width: "100%", resize: "none", border: "none" }}
                value={props.text[5]}
                onChange={(e) => props.setValue(e.target.value, 5)}
              />
            </td>
          </tr>
          <tr>
            <th>{props.t("scenarios.question7")}</th>
          </tr>
          <tr>
            <td style={{ padding: "0.2rem" }}>
              <textarea
                rows="2"
                cols="400"
                style={{ width: "100%", resize: "none", border: "none" }}
                value={props.text[6]}
                onChange={(e) => props.setValue(e.target.value, 6)}
              />
            </td>
          </tr>
        </tbody>
      </table>
      <button
        className="btn btn-secondary float-right mt-3"
        onClick={() => props.onChange(props.id, svg)}
      >
        {props.t("save")}
      </button>
    </div>
  );
}

function mapStateToProps(state) {
  return state.scenarios;
}

export default connect(mapStateToProps, { setValue })(
  withTranslation()(Scenarios)
);
