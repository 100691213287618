import { combineReducers } from "redux";
import assessment from "./assessment";
import personalityStyle from "./personalityStyle";
import introvertExtrovert from "./introvertExtrovert";
import teachability from "./teachability.js";
import intellectualHumility from "./intellectualHumility";
import gritScale from "./gritScale";
import emotionalSelfAwareness from "./emotionalSelfAwareness";
import visionaryImplementer from "./visionaryImplementer";
import skills from "./skills";
import delegation from "./delegation";
import scenarios from "./scenarios";
import form from "./form";

const rootReducer = combineReducers({
  assessment,
  personalityStyle,
  introvertExtrovert,
  teachability,
  intellectualHumility,
  gritScale,
  emotionalSelfAwareness,
  visionaryImplementer,
  skills,
  delegation,
  scenarios,
  form,
});

export default rootReducer;
