import React from "react";
import "./scss/input.scss";

function Input(props) {
  return (
    <>
      {props.showInputEle ? (
        <input
          className="click-input"
          type="text"
          value={props.value}
          onChange={props.handleChange}
          onBlur={props.handleBlur}
          autoFocus
        />
      ) : (
        <span
          className="click-text"
          onClick={props.handleDoubleClick}
          onFocus={props.handleDoubleClick}
          tabindex="0"
        >
          {props.value}
        </span>
      )}
    </>
  );
}

export default Input;
