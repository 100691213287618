import { SET_VALUE_IH } from "../actionTypes";

const DEFAULT_STATE = {
  grade: [0, 100],
  values: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
};

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case SET_VALUE_IH:
      const values = [...state.values];
      values[action.idx] = action.val;
      const point = values.reduce((a, b) => a + b, 0) / 0.6;
      const grade = [point, 100 - point];
      return { grade, values };
    default:
      return state;
  }
};
