import React, { Component } from "react";
import PersonalityStyle from "./PersonalityStyle";
import IntrovertExtrovert from "./IntrovertExtrovert";
import Teachability from "./Teachability";
import IntellectualHumility from "./IntellectualHumility";
import GritScale from "./GritScale";
import EmotionalSelfAwareness from "./EmotionalSelfAwareness";
import VisionaryImplementer from "./VisionaryImplementer";
import Skills from "./Skills";
import Delegation from "./Delegation";
import Scenarios from "./Scenarios";
import Form from "./Form";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getAssessment, setSVG } from "../store/actions/accessment";
import { deserialize, serialize } from "react-serialize";
import { withTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

class Assessment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      subItems: [
        {
          item: PersonalityStyle,
          svgBackup: null,
          name: "assessment.personality",
        },
        {
          item: IntrovertExtrovert,
          svgBackup: null,
          name: "assessment.introvert",
        },
        {
          item: Teachability,
          svgBackup: null,
          name: "assessment.teachability",
        },
        {
          item: IntellectualHumility,
          svgBackup: null,
          name: "assessment.humility",
        },
        { item: GritScale, svgBackup: null, name: "assessment.grit" },
        {
          item: EmotionalSelfAwareness,
          svgBackup: null,
          name: "assessment.awareness",
        },
        {
          item: VisionaryImplementer,
          svgBackup: null,
          name: "assessment.visionary",
        },
        { item: Skills, svgBackup: null, name: "assessment.skills" },
        { item: Delegation, svgBackup: null, name: "assessment.delegation" },
        { item: Scenarios, svgBackup: null, name: "assessment.scenarios" },
        { item: Form, svgBackup: null },
      ],
      currentIndex: 0,
    };

    this.changeCurrentIndex = this.changeCurrentIndex.bind(this);
  }

  componentDidMount() {
    if (this.props.match.params.id) {
      this.props.getAssessment(this.props.match.params.id, () => {
        const subItems = this.state.subItems.map((a, i) => ({
          ...a,
          svgBackup: this.props.svg[i],
        }));
        this.setState({ subItems });
      });
    }
  }

  changeCurrentIndex(i, svg, next = true) {
    const subItems = this.state.subItems.map((e, j) => {
      this.props.setSVG(e.svgBackup, j);
      return e;
    });
    if (svg !== null) {
      svg = serialize(svg);
      this.props.setSVG(svg, i);
      this.props.setSVG(null, i + 1);
      subItems[i].svgBackup = svg;
    } else {
      this.props.setSVG(svg, i);
    }

    this.setState({ subItems, currentIndex: next ? i + 1 : i });
  }

  render() {
    const currentElement = this.state.subItems.map((d, i) =>
      i === this.state.currentIndex ? (
        <d.item key={i} onChange={this.changeCurrentIndex} id={i} />
      ) : null
    );

    const subItems = this.props.svg.map((d, i) =>
      d !== null ? (
        <div
          key={i}
          className="col-2 pb-2 px-1 col-md-3 pd-md-0 col-lg-2"
          onClick={() => this.changeCurrentIndex(i, null, false)}
          style={{ cursor: "pointer" }}
        >
          {deserialize(d)}
          <p className="d-none d-md-block">
            {this.props.t(this.state.subItems[i].name)}
          </p>
        </div>
      ) : this.state.subItems[i].name !== undefined ? (
        <div key={i} className="col-2 pb-2 px-1 col-md-3 pd-md-0 col-lg-2">
          <svg
            version="1.1"
            baseProfile="full"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="xMinYMin meet"
            viewBox="0 0 200 200"
          >
            <rect x="0" y="0" width="200" height="200" fill="#efefef" />
          </svg>
          <p className="d-none d-md-block">
            {this.props.t(this.state.subItems[i].name)}
          </p>
        </div>
      ) : null
    );

    return (
      <>
        <Helmet>
          <title>THNQ Assessment</title>
          <meta name="description" content="Personal business assessment" />

          {/* <!-- Facebook Meta Tags --> */}
          <meta property="og:url" content="https://twem.ca/assessment" />
          <meta property="og:type" content="website" />
          <meta property="og:title" content="THNQ Assessment" />
          <meta
            property="og:description"
            content="Personal business assessment"
          />
          <meta property="og:image" content="" />

          {/* <!-- Twitter Meta Tags --> */}
          <meta name="twitter:card" content="summary_large_image" />
          <meta property="twitter:domain" content="twem.ca" />
          <meta property="twitter:url" content="https://twem.ca/assessment" />
          <meta name="twitter:title" content="THNQ Assessment" />
          <meta
            name="twitter:description"
            content="Personal business assessment"
          />
          <meta name="twitter:image" content="" />
        </Helmet>
        <nav className="navbar navbar-dark bg-dark">
          <div className="container">
            <h1 className="navbar-brand">{this.props.t("assessment.label")}</h1>
          </div>
        </nav>
        <div className="container pt-3 pt-md-5">
          <h6 className="pb-2">{this.props.t("assessment.desc")}</h6>
          <div className="row pb-3 pb-md-5">{subItems}</div>
          {currentElement}
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  return state.assessment;
}

export default connect(mapStateToProps, { getAssessment, setSVG })(
  withRouter(withTranslation()(Assessment))
);
