import React, { Component } from "react";
import { connect } from "react-redux";
import * as d3 from "d3";
import { setValue } from "../store/actions/emotionalSelfAwareness";
import { withTranslation } from "react-i18next";

class EmotionalSelfAwareness extends Component {
  constructor(props) {
    super(props);

    this.width = 600;
    this.height = 600;
    this.subAreasPerNum = 3;

    this.createPieChart = this.createPieChart.bind(this);
    this.drawGraph = this.drawGraph.bind(this);

    this.colorScaleAreas = d3
      .scaleOrdinal()
      .domain(this.props.areasDefault.map((_, i) => i))
      .range(["#10253f", "#1e1c11", "#403152", "#665500", "#984807"]);

    this.colorScaleSubAreas = d3
      .scaleOrdinal()
      .domain(this.props.subAreasDefault.map((_, i) => i))
      .range([
        "#17375e",
        "#1f497d",
        "#558ed5",
        "#4a452a",
        "#948a54",
        "#c4bd97",
        "#604a7b",
        "#8064a2",
        "#b3a2c7",
        "#806a00",
        "#b39500",
        "#aaaf45",
        "#e46c0a",
        "#f79646",
        "#fac090",
      ]);
  }

  static defaultProps = {
    areasDefault: [
      { class: "self-awareness", name: "selfAwareness.awareness" },
      { class: "leading-self", name: "selfAwareness.leadingSelf" },
      { class: "focus", name: "selfAwareness.focus" },
      { class: "people", name: "selfAwareness.people" },
      { class: "communication", name: "selfAwareness.communication" },
    ],

    subAreasDefault: [
      {
        class: "effective-listening",
        name: "selfAwareness.effective.label",
        subQuestions: [
          {
            inputValue: [1, 2, 3, 4],
            ask: "selfAwareness.effective.question1",
          },
          {
            inputValue: [1, 2, 3, 4],
            ask: "selfAwareness.effective.question2",
          },
        ],
      },
      {
        class: "feedback",
        name: "selfAwareness.feedback.label",
        subQuestions: [
          {
            inputValue: [1, 2, 3, 4],
            ask: "selfAwareness.feedback.question1",
          },
          {
            inputValue: [1, 2, 3, 4],
            ask: "selfAwareness.feedback.question2",
          },
        ],
      },
      {
        class: "empathy",
        name: "selfAwareness.empathy.label",
        subQuestions: [
          {
            inputValue: [1, 2, 3, 4],
            ask: "selfAwareness.empathy.question1",
          },
          {
            inputValue: [1, 2, 3, 4],
            ask: "selfAwareness.empathy.question2",
          },
        ],
      },
      {
        class: "executing-tasks",
        name: "selfAwareness.tasks.label",
        subQuestions: [
          {
            inputValue: [1, 2, 3, 4],
            ask: "selfAwareness.tasks.question1",
          },
          {
            inputValue: [1, 2, 3, 4],
            ask: "selfAwareness.tasks.question2",
          },
        ],
      },
      {
        class: "time-management",
        name: "selfAwareness.management.label",
        subQuestions: [
          {
            inputValue: [1, 2, 3, 4],
            ask: "selfAwareness.management.question1",
          },
          {
            inputValue: [1, 2, 3, 4],
            ask: "selfAwareness.management.question2",
          },
        ],
      },
      {
        class: "questioning",
        name: "selfAwareness.quo.label",
        subQuestions: [
          {
            inputValue: [1, 2, 3, 4],
            ask: "selfAwareness.quo.question1",
          },
          {
            inputValue: [1, 2, 3, 4],
            ask: "selfAwareness.quo.question2",
          },
        ],
      },
      {
        class: "obsession",
        name: "selfAwareness.obsession.label",
        subQuestions: [
          {
            inputValue: [1, 2, 3, 4],
            ask: "selfAwareness.obsession.question1",
          },
          {
            inputValue: [1, 2, 3, 4],
            ask: "selfAwareness.obsession.question2",
          },
        ],
      },
      {
        class: "tenacity",
        name: "selfAwareness.tenacity.label",
        subQuestions: [
          {
            inputValue: [1, 2, 3, 4],
            ask: "selfAwareness.tenacity.question1",
          },
          {
            inputValue: [1, 2, 3, 4],
            ask: "selfAwareness.tenacity.question2",
          },
        ],
      },
      {
        class: "objectivity",
        name: "selfAwareness.objectivity.label",
        subQuestions: [
          {
            inputValue: [1, 2, 3, 4],
            ask: "selfAwareness.objectivity.question1",
          },
          {
            inputValue: [1, 2, 3, 4],
            ask: "selfAwareness.objectivity.question2",
          },
        ],
      },
      {
        class: "delegating",
        name: "selfAwareness.delegating.label",
        subQuestions: [
          {
            inputValue: [1, 2, 3, 4],
            ask: "selfAwareness.delegating.question1",
          },
          {
            inputValue: [1, 2, 3, 4],
            ask: "selfAwareness.delegating.question2",
          },
        ],
      },
      {
        class: "inspiring-others",
        name: "selfAwareness.inspiring.label",
        subQuestions: [
          {
            inputValue: [1, 2, 3, 4],
            ask: "selfAwareness.inspiring.question1",
          },
          {
            inputValue: [1, 2, 3, 4],
            ask: "selfAwareness.inspiring.question2",
          },
        ],
      },
      {
        class: "organizing-people",
        name: "selfAwareness.organizing.label",
        subQuestions: [
          {
            inputValue: [1, 2, 3, 4],
            ask: "selfAwareness.organizing.question1",
          },
          {
            inputValue: [1, 2, 3, 4],
            ask: "selfAwareness.organizing.question2",
          },
        ],
      },
      {
        class: "impulse-control",
        name: "selfAwareness.impulse.label",
        subQuestions: [
          {
            inputValue: [1, 2, 3, 4],
            ask: "selfAwareness.impulse.question1",
          },
          {
            inputValue: [1, 2, 3, 4],
            ask: "selfAwareness.impulse.question2",
          },
        ],
      },
      {
        class: "expressing-care",
        name: "selfAwareness.expressing.label",
        subQuestions: [
          {
            inputValue: [1, 2, 3, 4],
            ask: "selfAwareness.expressing.question1",
          },
          {
            inputValue: [1, 2, 3, 4],
            ask: "selfAwareness.expressing.question2",
          },
        ],
      },
      {
        class: "business-writing",
        name: "selfAwareness.business.label",
        subQuestions: [
          {
            inputValue: [1, 2, 3, 4],
            ask: "selfAwareness.business.question1",
          },
          {
            inputValue: [1, 2, 3, 4],
            ask: "selfAwareness.business.question2",
          },
        ],
      },
    ],
  };

  componentDidMount() {
    this.createPieChart();
  }

  componentDidUpdate() {
    this.drawGraph();
  }

  createPieChart() {
    const svg = d3
      .select(".ESA")
      .attr("width", "100%")
      .attr("viewBox", `0 0 ${this.width} ${this.height}`);

    svg
      .append("g")
      .attr("transform", `translate(${this.width / 2}, ${this.height / 2})`)
      .classed("chart-ESA", true);

    svg
      .append("g")
      .attr("transform", `translate(${this.width / 2}, ${this.height / 2})`)
      .classed("inner-chart-ESA", true);

    this.drawGraph();
  }

  drawGraph() {
    const arcs = d3.pie().value(1)(this.props.areas);

    const path = d3
      .arc()
      .outerRadius((d) => (d.data.value * this.width) / 15 - 30)
      .innerRadius(0);

    const update = d3.select(".chart-ESA").selectAll(".arc-ESA").data(arcs);

    update.exit().remove();

    update
      .enter()
      .append("path")
      .classed("arc-ESA", true)
      .merge(update)
      .attr("fill", (_, i) => this.colorScaleAreas(i))
      .attr("d", path);

    const textPath = d3
      .arc()
      .outerRadius(this.width / 2)
      .innerRadius(this.width / 2);

    update
      .enter()
      .append("text")
      .attr("transform", (d, i) => {
        if (i === 1 || i === 2 || i === 3) {
          return `translate(${textPath.centroid(d)}) rotate(${
            ((d.startAngle + d.endAngle) * 90) / Math.PI + 180
          })`;
        } else {
          return `translate(${textPath.centroid(d)}) rotate(${
            ((d.startAngle + d.endAngle) * 90) / Math.PI
          })`;
        }
      })
      .attr("dy", (_, i) => (i === 1 || i === 2 || i === 3 ? -18 : 20))
      .attr("text-anchor", "middle")
      .attr("alignment-baseline", "middle")
      .style("color", "#000")
      .style("font-size", "2rem")
      .text((_, i) => this.props.t(this.props.areasDefault[i].name));

    const arcsInner = d3.pie().value(1)(this.props.subAreas);

    const pathInner = d3
      .arc()
      .outerRadius((d) => (d.data.value * this.width) / 15 - 20)
      .innerRadius(0);

    const updateInner = d3
      .select(".inner-chart-ESA")
      .selectAll(".arc-ESA")
      .data(arcsInner);

    updateInner.exit().remove();

    updateInner
      .enter()
      .append("path")
      .classed("arc-ESA", true)
      .merge(updateInner)
      .attr("fill", (_, i) => this.colorScaleSubAreas(i))
      .attr("d", pathInner);
  }

  render() {
    const elements = this.props.subAreasDefault.map((a, i) => {
      if (i % this.subAreasPerNum === 0)
        return (
          <React.Fragment key={i}>
            <tr>
              <th
                className="text-left"
                colSpan="6"
                style={{
                  backgroundColor: this.colorScaleAreas(
                    Math.floor(i / this.subAreasPerNum)
                  ),
                }}
              >
                {this.props.t(
                  this.props.areasDefault[Math.floor(i / this.subAreasPerNum)]
                    .name
                )}
              </th>
            </tr>
            {a.subQuestions.map((b, j) => (
              <tr
                key={j}
                style={{ backgroundColor: this.colorScaleSubAreas(i) }}
              >
                {j === 0 ? (
                  <td rowSpan="2" style={{ padding: "0.3rem" }}>
                    {this.props.t(a.name)}
                  </td>
                ) : null}
                <td style={{ padding: "0.3rem" }}>{this.props.t(b.ask)}</td>
                {b.inputValue.map((c, k) => (
                  <td key={k} style={{ padding: "0" }}>
                    <label
                      style={{ padding: "0.75rem 0", width: "100%", margin: 0 }}
                    >
                      <input
                        type="radio"
                        name={`${a.class}-${j}`}
                        value={c}
                        style={{ transform: "scale(1.5)", padding: "0.3rem" }}
                        onChange={(e) =>
                          this.props.setValue(
                            +e.target.value,
                            i,
                            j,
                            this.subAreasPerNum
                          )
                        }
                        checked={
                          c ===
                          this.props.subAreas[i].subQuestions[j].checkedValue
                        }
                      />
                    </label>
                  </td>
                ))}
              </tr>
            ))}
          </React.Fragment>
        );
      else
        return (
          <React.Fragment key={i}>
            {a.subQuestions.map((b, j) => (
              <tr
                key={j}
                style={{ backgroundColor: this.colorScaleSubAreas(i) }}
              >
                {j === 0 ? (
                  <td
                    rowSpan="2"
                    style={{
                      backgroundColor: this.colorScaleSubAreas(i),
                      padding: "0.3rem",
                    }}
                  >
                    {this.props.t(a.name)}
                  </td>
                ) : null}
                <td style={{ padding: "0.3rem" }}>{this.props.t(b.ask)}</td>
                {b.inputValue.map((c, k) => (
                  <td key={k} style={{ padding: "0" }}>
                    <label
                      style={{ padding: "0.75rem 0", width: "100%", margin: 0 }}
                    >
                      <input
                        type="radio"
                        name={`${a.class}-${j}`}
                        value={c}
                        style={{ transform: "scale(1.5)", padding: "0.3rem" }}
                        onChange={(e) =>
                          this.props.setValue(
                            +e.target.value,
                            i,
                            j,
                            this.subAreasPerNum
                          )
                        }
                        checked={
                          c ===
                          this.props.subAreas[i].subQuestions[j].checkedValue
                        }
                      />
                    </label>
                  </td>
                ))}
              </tr>
            ))}
          </React.Fragment>
        );
    });

    return (
      <div className="row">
        <div className="col-md-4 pb-3">
          <div className="col-6 offset-3 col-md-12 offset-md-0">
            <svg
              className="ESA"
              version="1.1"
              baseProfile="full"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="xMinYMin meet"
            ></svg>
          </div>
        </div>
        <div className="col-md-8">
          <h1>{this.props.t("selfAwareness.label")}</h1>
          <p>{this.props.t("selfAwareness.desc")}</p>
          <table className="table table-dark table-bordered">
            <thead>
              <tr>
                <th style={{ padding: "0.3rem" }}>{this.props.t("area")}</th>
                <th style={{ padding: "0.3rem" }}>
                  {this.props.t("questions")}
                </th>
                <th style={{ padding: "0.3rem" }}>{this.props.t("notLike")}</th>
                <th style={{ padding: "0.3rem" }}>
                  N{this.props.t("notMuch")}
                </th>
                <th style={{ padding: "0.3rem" }}>
                  {this.props.t("somewhat")}
                </th>
                <th style={{ padding: "0.3rem" }}>{this.props.t("very")}</th>
              </tr>
            </thead>
            <tbody>{elements}</tbody>
          </table>
          <button
            className="btn btn-secondary float-right mt-3"
            onClick={() =>
              this.props.onChange(
                this.props.id,
                <div
                  dangerouslySetInnerHTML={{
                    __html: d3.select(".ESA").node().outerHTML,
                  }}
                />
              )
            }
          >
            {this.props.t("save")}
          </button>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return state.emotionalSelfAwareness;
}

export default connect(mapStateToProps, { setValue })(
  withTranslation()(EmotionalSelfAwareness)
);
