import React from "react";
import { useContext, useCallback, useEffect } from "react";
import { useHistory, withRouter } from "react-router-dom";
import Dashboard from "./Dashboard";
import Login from "./Login";
import { UserContext } from "./UserContext";

function DashboardWrapper() {
  const { token, setToken } = useContext(UserContext);
  const history = useHistory();

  const verifyUser = useCallback(() => {
    fetch("https://twem.ca/api/refreshToken", {
      method: "POST",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    })
      .then((data) => data.json())
      .then((data) => {
        if (data.token) {
          setToken(data.token);
          history.push("/cts/");
        } else {
          setToken("");
          history.push("/login/");
        }

        setTimeout(verifyUser, 30 * 60 * 1000);
      });
  }, [setToken, history]);

  useEffect(() => {
    verifyUser();
  }, [verifyUser]);

  return token !== "" ? <Dashboard /> : <Login />;
}

export default withRouter(DashboardWrapper);
