import React from "react";
import { connect } from "react-redux";
import { setAssessment, updateAssessment } from "../store/actions/accessment";
import {
  setFirstNameValue,
  setLastNameValue,
  setEmailValue,
} from "../store/actions/form";
import { withTranslation } from "react-i18next";

function Form(props) {
  return (
    <form
      className="mt-5"
      onSubmit={(e) => {
        e.preventDefault();
        props.form.id === ""
          ? props.setAssessment(props)
          : props.updateAssessment(props);
      }}
    >
      <div className="form-row">
        <div className="col-md-6 mb-3 text-left">
          <label htmlFor="first-name">{props.t("firstname.label")}</label>
          <input
            type="text"
            className="form-control"
            id="first-name"
            onChange={(e) => props.setFirstNameValue(e.target.value)}
            value={props.form.firstName}
            required
          />
        </div>
        <div className="col-md-6 mb-3 text-left">
          <label htmlFor="last-name">{props.t("lastname.label")}</label>
          <input
            type="text"
            className="form-control"
            id="last-name"
            onChange={(e) => props.setLastNameValue(e.target.value)}
            value={props.form.lastName}
            required
          />
        </div>
      </div>
      <div className="form-group text-left">
        <label htmlFor="email">{props.t("email.label")}</label>
        <input
          type="email"
          className="form-control"
          id="email"
          aria-describedby="emailHelp"
          onChange={(e) => props.setEmailValue(e.target.value)}
          value={props.form.email}
          required
        />
        <small id="emailHelp" className="form-text text-muted">
          {props.t("email.prompt1")}
        </small>
      </div>
      <button type="submit" className="btn btn-dark float-right mb-3">
        {props.t("submit.label")}
      </button>
    </form>
  );
}

function mapStateToProps(state) {
  return state;
}

export default connect(mapStateToProps, {
  setAssessment,
  updateAssessment,
  setFirstNameValue,
  setLastNameValue,
  setEmailValue,
})(withTranslation()(Form));
