import React, { Component } from "react";
import { connect } from "react-redux";
import * as d3 from "d3";
import { setValue } from "../store/actions/gritScale";
import { withTranslation } from "react-i18next";

class GritScale extends Component {
  constructor(props) {
    super(props);

    this.width = 200;
    this.height = 200;

    this.colorScale = d3
      .scaleOrdinal()
      .domain([0, 1])
      .range(["#28a745", "#d6d8db"]);

    this.createDonutChart = this.createDonutChart.bind(this);
    this.drawGraph = this.drawGraph.bind(this);
  }

  static defaultProps = {
    questions: [
      { ask: "grit.question1", op: [4, 3, 2, 1] },
      { ask: "grit.question2", op: [1, 2, 3, 4] },
      { ask: "grit.question3", op: [4, 3, 2, 1] },
      { ask: "grit.question4", op: [1, 2, 3, 4] },
      { ask: "grit.question5", op: [4, 3, 2, 1] },
      { ask: "grit.question6", op: [1, 2, 3, 4] },
      { ask: "grit.question7", op: [4, 3, 2, 1] },
      { ask: "grit.question8", op: [1, 2, 3, 4] },
      { ask: "grit.question9", op: [4, 3, 2, 1] },
      { ask: "grit.question10", op: [1, 2, 3, 4] },
    ],
  };

  componentDidMount() {
    this.createDonutChart();
  }

  componentDidUpdate() {
    this.drawGraph();
  }

  createDonutChart() {
    const svg = d3
      .select(".GS")
      .attr("viewBox", `0 0 ${this.width} ${this.height}`);

    svg
      .append("g")
      .attr("transform", `translate(${this.width / 2}, ${this.height / 2})`)
      .classed("chartGS", true);

    svg
      .append("text")
      .attr("x", this.width / 2)
      .attr("y", this.height / 2)
      .attr("text-anchor", "middle")
      .attr("alignment-baseline", "middle")
      .classed("gradeGS", true);

    svg
      .append("text")
      .attr("x", this.width / 2)
      .attr("y", 60)
      .attr("text-anchor", "middle")
      .attr("alignment-baseline", "hanging")
      .attr("fill", "#000")
      .attr("font-size", "1.5rem")
      .text("G.");

    this.drawGraph();
  }

  drawGraph() {
    const arcs = d3.pie().sort(null)(this.props.grade);

    const path = d3
      .arc()
      .outerRadius(this.width / 2)
      .innerRadius(this.width / 4);

    const update = d3.select(".chartGS").selectAll(".arcGS").data(arcs);

    update.exit().remove();

    update
      .enter()
      .append("path")
      .classed("arcGS", true)
      .merge(update)
      .attr("fill", (_, i) => this.colorScale(i))
      .attr("d", path);

    d3.select(".gradeGS").text(`${Math.round(this.props.grade[0])}%`);
  }

  render() {
    return (
      <div className="row">
        <div className="col-md-3 pb-3">
          <div className="col-6 offset-3 col-md-12 offset-md-0">
            <svg
              className="GS"
              version="1.1"
              baseProfile="full"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="xMinYMin meet"
            ></svg>
          </div>
        </div>
        <div className="col-md-9">
          <h2>{this.props.t("assessment.grit")}</h2>
          <p>
            {this.props.t("grit.desc1")}{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://angeladuckworth.com/grit-scale/"
            >
              {this.props.t("grit.here")}
            </a>
          </p>
          <p>{this.props.t("grit.desc2")}</p>
          <table className="table table-hover">
            <thead className="thead-dark">
              <tr>
                <th>{this.props.t("questions")}</th>
                <th style={{ fontSize: "0.7rem" }}>
                  {this.props.t("notLike")}
                </th>
                <th style={{ fontSize: "0.7rem" }}>
                  {this.props.t("notMuch")}
                </th>
                <th style={{ fontSize: "0.7rem" }}>
                  {this.props.t("somewhat")}
                </th>
                <th style={{ fontSize: "0.7rem" }}>{this.props.t("very")}</th>
              </tr>
            </thead>
            <tbody>
              {this.props.questions.map((d, i) => (
                <tr key={i}>
                  <td className="text-left">{this.props.t(d.ask)}</td>
                  {d.op.map((d, j) => (
                    <td key={j} style={{ padding: "0" }}>
                      <label
                        style={{
                          padding: "0.75rem 0",
                          width: "100%",
                          margin: 0,
                        }}
                      >
                        <input
                          type="radio"
                          name={i}
                          value={d}
                          checked={d === this.props.values[i]}
                          onChange={(e) =>
                            this.props.setValue(+e.target.value, i)
                          }
                          style={{ transform: "scale(1.5)" }}
                        />
                      </label>
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
          <button
            className="btn btn-secondary float-right mt-3"
            onClick={() =>
              this.props.onChange(
                this.props.id,
                <div
                  dangerouslySetInnerHTML={{
                    __html: d3.select(".GS").node().outerHTML,
                  }}
                />
              )
            }
          >
            {this.props.t("save")}
          </button>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return state.gritScale;
}

export default connect(mapStateToProps, { setValue })(
  withTranslation()(GritScale)
);
