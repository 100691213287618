import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { UserContext } from "./UserContext";
import "./scss/Login.scss";

class Login extends Component {
  static contextType = UserContext;
  constructor(props) {
    super(props);

    this.state = {
      id: "",
      email: "",
      loginPassword: "",
      registerPassword: "",
      confirmPassword: "",
      errorMessage: "",
      currentView: "logIn",
    };

    this.changeView = this.changeView.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handleLoginPasswordChange = this.handleLoginPasswordChange.bind(this);
    this.handleLoginSubmit = this.handleLoginSubmit.bind(this);
    this.handleForgotPassword = this.handleForgotPassword.bind(this);
    this.handleRegisterPasswordChange =
      this.handleRegisterPasswordChange.bind(this);
    this.handleConfirmPasswordChange =
      this.handleConfirmPasswordChange.bind(this);
    this.handleCheckPassword = this.handleCheckPassword.bind(this);
    this.handleRegisterSubmit = this.handleRegisterSubmit.bind(this);
    this.currentView = this.currentView.bind(this);
  }

  changeView = (view) => {
    this.setState({
      currentView: view,
      errorMessage: "",
    });
  };

  handleEmailChange(event) {
    this.setState({ email: event.target.value });
  }

  handleLoginPasswordChange(event) {
    this.setState({ loginPassword: event.target.value });
  }

  handleLoginSubmit(event) {
    event.preventDefault();
    let url = "https://twem.ca/api/login";
    fetch(url, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
      }),
      credentials: "include",
      body: JSON.stringify({
        username: this.state.email,
        password: this.state.loginPassword,
      }),
    })
      .then((data) => data.json())
      .then((data) => {
        if (data.message !== "Successfully") {
          this.setState({ errorMessage: data.message });
        } else {
          const { token, setToken } = this.context;
          setToken(data.token);
          this.props.history.push("/cts/");
        }
      });
  }

  handleForgotPassword(event) {
    event.preventDefault();
    let url = "https://twem.ca/api/forgot";
    fetch(url, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
      }),
      body: JSON.stringify({
        username: this.state.email,
      }),
    })
      .then((data) => data.json())
      .then((data) => this.setState({ errorMessage: data.message }));
  }

  handleRegisterPasswordChange(event) {
    this.setState({ registerPassword: event.target.value });
  }

  handleConfirmPasswordChange(event) {
    this.setState({ confirmPassword: event.target.value });
    if (this.handleCheckPassword(event.target.value)) {
      this.setState({
        errorMessage: "The passwords you entered do not match.",
      });
    } else {
      this.setState({ errorMessage: "" });
    }
  }

  handleCheckPassword(value) {
    if (value !== this.state.registerPassword) {
      return -1;
    }
    return 0;
  }

  handleRegisterSubmit(event) {
    event.preventDefault();
    if (
      this.state.errorMessage &&
      this.state.errorMessage !== "User exists, please login"
    ) {
      return;
    }
    let url = "https://twem.ca/api/register";
    fetch(url, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
      }),
      body: JSON.stringify({
        username: this.state.email,
        password: this.state.registerPassword,
      }),
    })
      .then((data) => data.json())
      .then((data) => {
        if (data.message !== "Successfully") {
          this.setState({ errorMessage: data.message });
        } else {
          this.setState({
            errorMessage: "Account is created, please Login",
          });
          setTimeout(() => this.changeView("logIn"), 3000);
        }
      });
  }

  currentView() {
    switch (this.state.currentView) {
      case "signUp":
        return (
          <form onSubmit={this.handleRegisterSubmit}>
            <h2>Sign Up!</h2>
            <fieldset>
              <legend>Create Account</legend>
              <ul>
                <li>
                  <label htmlFor="email">Email:</label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    value={this.state.email}
                    onChange={this.handleEmailChange}
                    required
                  />
                </li>
                <li>
                  <label htmlFor="password">Password:</label>
                  <input
                    type="password"
                    name="password"
                    id="password"
                    value={this.state.registerPassword}
                    onChange={this.handleRegisterPasswordChange}
                    required
                  />
                </li>
                <li>
                  <label htmlFor="confirm-password">Confirm Password:</label>
                  <input
                    type="password"
                    name="confirm-password"
                    id="confirm-password"
                    value={this.state.confirmPassword}
                    onChange={this.handleConfirmPasswordChange}
                    required
                  />
                </li>
              </ul>
            </fieldset>
            {this.state.errorMessage && (
              <p style={{ color: "red" }}>{this.state.errorMessage}</p>
            )}
            <button type="submit">Submit</button>
            <button type="button" onClick={() => this.changeView("logIn")}>
              Have an Account?
            </button>
          </form>
        );
      case "logIn":
        return (
          <form onSubmit={this.handleLoginSubmit}>
            <h2>Welcome Back!</h2>
            <fieldset>
              <legend>Log In</legend>
              <ul>
                <li>
                  <label htmlFor="email">Email</label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    value={this.state.email}
                    onChange={this.handleEmailChange}
                    required
                  />
                </li>
                <li>
                  <label htmlFor="password">Password</label>
                  <input
                    type="password"
                    name="password"
                    id="password"
                    value={this.state.loginPassword}
                    onChange={this.handleLoginPasswordChange}
                    required
                  />
                </li>
                <li>
                  <i />
                  <a onClick={() => this.changeView("PWReset")}>
                    Forgot Password?
                  </a>
                </li>
              </ul>
            </fieldset>
            {this.state.errorMessage && (
              <p style={{ color: "red" }}>{this.state.errorMessage}</p>
            )}
            <button type="submit">Login</button>
            <button type="button" onClick={() => this.changeView("signUp")}>
              Create an Account
            </button>
          </form>
        );
      case "PWReset":
        return (
          <form onSubmit={this.handleForgotPassword}>
            <h2>Reset Password</h2>
            <fieldset>
              <legend>Password Reset</legend>
              <ul>
                <li>
                  <em>A reset link will be sent to your inbox!</em>
                </li>
                <li>
                  <label htmlFor="email">Email:</label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    value={this.state.email}
                    onChange={this.handleEmailChange}
                    required
                  />
                </li>
              </ul>
            </fieldset>
            {this.state.errorMessage && <p>{this.state.errorMessage}</p>}
            <button type="submit">Send Reset Link</button>
            <button type="button" onClick={() => this.changeView("logIn")}>
              Go Back
            </button>
          </form>
        );
      default:
        break;
    }
  }

  render() {
    return <section id="entry-page">{this.currentView()}</section>;
  }
}

export default withRouter(Login);
