import React, { Component } from "react";
import { connect } from "react-redux";
import { setGrade } from "../store/actions/teachability";
import { withTranslation } from "react-i18next";

class Teachability extends Component {
  constructor(props) {
    super(props);
    this.generateSVG = this.generateSVG.bind(this);
  }

  static defaultProps = {
    questionare1: {
      title: "teachability.title1",
      content: [
        "teachability.content1-1",
        "teachability.content1-2",
        "teachability.content1-3",
        "teachability.content1-4",
        "teachability.content1-5",
        "teachability.content1-6",
        "teachability.content1-7",
        "teachability.content1-8",
      ],
    },
    questionare2: {
      title: "teachability.title2",
      content: [
        "teachability.content2-1",
        "teachability.content2-2",
        "teachability.content2-3",
        "teachability.content2-4",
        "teachability.content2-5",
        "teachability.content2-6",
        "teachability.content2-7",
        "teachability.content2-8",
      ],
    },
  };

  generateSVG() {
    return (
      <svg
        version="1.1"
        baseProfile="full"
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="xMinYMin meet"
        viewBox="0 0 100 100"
      >
        <rect x="0" y="0" width="100" height="100" fill="gray" />
        <rect
          x="0"
          y={100 - this.props.grade}
          width="100"
          height={this.props.grade}
          fill="orange"
        />
        <text
          x="50"
          y={(200 - this.props.grade) / 2}
          fill="#007bff"
          textAnchor="middle"
          alignmentBaseline="middle"
        >
          {this.props.grade}%
        </text>
        <text
          x="50"
          y={(150 - this.props.grade) / 2}
          textAnchor="middle"
          alignmentBaseline="hanging"
          fontSize="0.75rem"
          fill="#fff"
        >
          T&H
        </text>
      </svg>
    );
  }

  render() {
    return (
      <div className="row">
        <div className="col-md-3 pb-3">
          <label>{this.props.t("teachability.desc")}</label>
          <input
            type="range"
            min="0"
            max="10"
            step="1"
            value={this.props.grade / 10}
            onChange={(e) => this.props.setGrade(+e.target.value * 10)}
          />
          <div className="col-6 offset-3 col-md-12 offset-md-0">
            {this.generateSVG()}
          </div>
        </div>
        <div className="col-md-9">
          <h2>{this.props.t("assessment.teachability")}</h2>
          <h5 className="text-left">
            {this.props.t(this.props.questionare1.title)}
          </h5>
          <ul className="list-group">
            {this.props.questionare1.content.map((d, i) => (
              <li
                key={i}
                className="list-group-item list-group-item-secondary text-left"
              >
                {this.props.t(d)}
              </li>
            ))}
          </ul>
          <h5 className="text-left mt-3">
            {this.props.t(this.props.questionare2.title)}
          </h5>
          <ul className="list-group">
            {this.props.questionare2.content.map((d, i) => (
              <li
                key={i}
                className="list-group-item list-group-item-secondary text-left"
              >
                {this.props.t(d)}
              </li>
            ))}
          </ul>
          <button
            className="btn btn-secondary float-right mt-3"
            onClick={() =>
              this.props.onChange(this.props.id, this.generateSVG())
            }
          >
            {this.props.t("save")}
          </button>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return state.teachability;
}

export default connect(mapStateToProps, { setGrade })(
  withTranslation()(Teachability)
);
