import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "./scss/Login.scss";

class Reset extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: "",
      token: "",
      password: "",
      confirmPassword: "",
      errorMessage: "",
    };

    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handleConfirmPasswordChange =
      this.handleConfirmPasswordChange.bind(this);
    this.handleCheckPassword = this.handleCheckPassword.bind(this);
    this.handleResetSubmit = this.handleResetSubmit.bind(this);
  }

  handlePasswordChange(event) {
    this.setState({ password: event.target.value });
  }

  handleConfirmPasswordChange(event) {
    this.setState({ confirmPassword: event.target.value });
    if (this.handleCheckPassword(event.target.value)) {
      this.setState({
        errorMessage: "The passwords you entered do not match.",
      });
    } else {
      this.setState({ errorMessage: "" });
    }
  }

  handleCheckPassword(value) {
    if (value !== this.state.password) {
      return -1;
    }
    return 0;
  }

  handleResetSubmit(event) {
    event.preventDefault();
    if (this.state.errorMessage) {
      return;
    }
    let url = "https://twem.ca/api/reset";
    fetch(url, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
      }),
      body: JSON.stringify({
        id: this.state.id,
        token: this.state.token,
        password: this.state.password,
      }),
      // credentials: "include",
    })
      .then((data) => data.json())
      .then((data) => {
        if (data.message !== "Successfully") {
          this.setState({ errorMessage: data.message });
        } else {
          this.props.history.push("/login");
        }
      });
  }

  componentDidMount() {
    if (this.props.match.params.id && this.props.match.params.token) {
      this.setState({
        id: this.props.match.params.id,
        token: this.props.match.params.token,
      });
    }
  }

  render() {
    return (
      <section id="entry-page">
        <form onSubmit={this.handleResetSubmit}>
          <h2>Reset Password</h2>
          <fieldset>
            <ul>
              <li>
                <label htmlFor="password">Password:</label>
                <input
                  type="password"
                  name="password"
                  id="password"
                  value={this.state.password}
                  onChange={this.handlePasswordChange}
                  required
                />
              </li>
              <li>
                <label htmlFor="confirm-password">Confirm Password:</label>
                <input
                  type="password"
                  name="confirm-password"
                  id="confirm-password"
                  value={this.state.confirmPassword}
                  onChange={this.handleConfirmPasswordChange}
                  required
                />
              </li>
            </ul>
          </fieldset>
          {this.state.errorMessage && (
            <p style={{ color: "red" }}>{this.state.errorMessage}</p>
          )}
          <button type="submit">Submit</button>
          <button
            type="button"
            onClick={() => this.props.history.push("/login")}
          >
            Back to Login
          </button>
        </form>
      </section>
    );
  }
}

export default withRouter(Reset);
