import { GET_ASSESSMENT, SET_SVG } from "../actionTypes";
import { serialize } from "react-serialize";
import { setValue as setDelegation } from "./delegation";
import { setValue as setEmotionalSelfAwareness } from "./emotionalSelfAwareness";
import { setValue as setGritScale } from "./gritScale";
import { setValue as setIntellectualHumility } from "./intellectualHumility";
import {
  setValue as setIntrovertExtrovert,
  setOpacity as setIntrovertExtrovertOpacity,
} from "./introvertExtrovert";
import { setOpacity as setPersonalityStyle } from "./personalityStyle";
import { setValue as setScenarios } from "./scenarios";
import { setValue as setSkills } from "./skills";
import { setGrade as setTeachability } from "./teachability";
import { setValue as setVisionaryImplementer } from "./visionaryImplementer";
import {
  setIdValue,
  setFirstNameValue,
  setLastNameValue,
  setEmailValue,
} from "./form";
import $ from "jquery";

function handleGetAssessment(svg) {
  return {
    type: GET_ASSESSMENT,
    svg,
  };
}

export function getAssessment(id, callback) {
  return (dispatch) => {
    return fetch(`https://twem.ca/api/assessment/${id}`)
      .then((data) => data.json())
      .then((data) => {
        dispatch(handleGetAssessment(data.assessment.svg));
        data.delegation.text.map((d, i) => dispatch(setDelegation(d, i)));
        data.emotionalSelfAwareness.subAreas.map((s, i) =>
          s.subQuestions.map((q, j) =>
            dispatch(setEmotionalSelfAwareness(q.checkedValue, i, j, 3))
          )
        );
        data.gritScale.values.map((d, i) => dispatch(setGritScale(d, i)));
        data.intellectualHumility.values.map((d, i) =>
          dispatch(setIntellectualHumility(d, i))
        );
        if (data.introvertExtrovert.extrovert === 1)
          dispatch(setIntrovertExtrovertOpacity("extrovert"));
        if (data.introvertExtrovert.introvert === 1)
          dispatch(setIntrovertExtrovertOpacity("introvert"));
        dispatch(setIntrovertExtrovert(data.introvertExtrovert.value));
        if (data.personalityStyle.AC === 1) dispatch(setPersonalityStyle("AC"));
        if (data.personalityStyle.AD === 1) dispatch(setPersonalityStyle("AD"));
        if (data.personalityStyle.BC === 1) dispatch(setPersonalityStyle("BC"));
        if (data.personalityStyle.BD === 1) dispatch(setPersonalityStyle("BD"));
        data.scenarios.text.map((d, i) => dispatch(setScenarios(d, i)));
        data.skills.subAreas.map((s, i) =>
          s.subQuestions.map((q, j) =>
            dispatch(setSkills(q.checkedValue, i, j, 3))
          )
        );
        dispatch(setTeachability(data.teachability.grade));
        data.visionaryImplementer.items.map((d, i) =>
          d.questions.map((q, j) =>
            dispatch(setVisionaryImplementer(q.inputValue, i, j))
          )
        );
        dispatch(setIdValue(id));
        dispatch(setFirstNameValue(data.form.firstName));
        dispatch(setLastNameValue(data.form.lastName));
        dispatch(setEmailValue(data.form.email));
      })
      .then(() => callback());
  };
}

export function setAssessment(data) {
  return (dispatch) => {
    return fetch("https://twem.ca/api/assessment/", {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
      }),
      body: serialize(data),
    })
      .then((data) => data.json())
      .then((data) => dispatch(setIdValue(data._id)))
      .then(() => {
        $("body").css("overflow", "hidden");
        $(".ui-dialog").css("position", "fixed");
        $(".ui-dialog").css("display", "block");
      });
  };
}

export function updateAssessment(data) {
  return (dispatch) => {
    return fetch(`https://twem.ca/api/assessment/${data.form.id}`, {
      method: "PUT",
      headers: new Headers({
        "Content-Type": "application/json",
      }),
      body: serialize(data),
    }).then(() => {
      $("body").css("overflow", "hidden");
      $(".ui-dialog").css("position", "fixed");
      $(".ui-dialog").css("display", "block");
    });
  };
}

export function setSVG(svg, idx) {
  return {
    type: SET_SVG,
    svg,
    idx,
  };
}
