import React, { Component } from "react";
import { connect } from "react-redux";
import { setValue } from "../store/actions/visionaryImplementer";
import { withTranslation } from "react-i18next";

class VisionaryImplementer extends Component {
  constructor(props) {
    super(props);

    this.colors = ["#79c944", "#3fa9f5"];

    this.generateSVG = this.generateSVG.bind(this);
  }

  static defaultProps = {
    itemsDefault: [
      {
        name: "visionary.title1",
        questions: [
          { ask: "visionary.question1", values: [0, 1, 2, 3] },
          { ask: "visionary.question2", values: [0, 1, 2, 3] },
          { ask: "visionary.question3", values: [0, 1, 2, 3] },
        ],
      },
      {
        name: "visionary.title2",
        questions: [
          { ask: "visionary.question4", values: [0, 1, 2, 3] },
          { ask: "visionary.question5", values: [0, 1, 2, 3] },
          { ask: "visionary.question6", values: [0, 1, 2, 3] },
        ],
      },
    ],
  };

  generateSVG() {
    return (
      <svg
        version="1.1"
        baseProfile="full"
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="xMinYMin meet"
        viewBox="0 0 200 200"
      >
        <rect x="0" y="0" width="200" height="200" fill="#d6d8db" />
        <rect
          x={100 - this.props.items[0].value}
          y="0"
          width={this.props.items[0].value}
          height="200"
          fill={this.colors[0]}
        />
        <text
          x={(200 - this.props.items[0].value) / 2}
          y="60"
          textAnchor="middle"
          alignmentBaseline="hanging"
          fill="#fff"
          fontSize="1.5rem"
        >
          V
        </text>
        <text
          x={(200 - this.props.items[0].value) / 2}
          y="100"
          textAnchor="middle"
          alignmentBaseline="middle"
          fill="#fff"
        >
          {Math.round(this.props.items[0].value)}%
        </text>
        <rect
          x="100"
          y="0"
          width={this.props.items[1].value}
          height="200"
          fill={this.colors[1]}
        />
        <text
          x={(200 + this.props.items[1].value) / 2}
          y="60"
          textAnchor="middle"
          alignmentBaseline="hanging"
          fill="#fff"
          fontSize="1.5rem"
        >
          I
        </text>
        <text
          x={(200 + this.props.items[1].value) / 2}
          y="100"
          textAnchor="middle"
          alignmentBaseline="middle"
          fill="#fff"
        >
          {Math.round(this.props.items[1].value)}%
        </text>
      </svg>
    );
  }

  render() {
    const elements = this.props.itemsDefault.map((e, i) =>
      e.questions.map((q, j) => (
        <tr key={j} style={{ backgroundColor: this.colors[i] }}>
          {j === 0 ? <td rowSpan="3">{this.props.t(e.name)}</td> : null}
          <td>{this.props.t(q.ask)}</td>
          {q.values.map((v, k) => (
            <td key={k} style={{ padding: "0" }}>
              <label style={{ padding: "0.75rem 0", width: "100%", margin: 0 }}>
                <input
                  type="radio"
                  value={v}
                  onChange={(e) => this.props.setValue(+e.target.value, i, j)}
                  checked={v === this.props.items[i].questions[j].inputValue}
                  style={{ transform: "scale(1.5)" }}
                />
              </label>
            </td>
          ))}
        </tr>
      ))
    );

    return (
      <div className="row">
        <div className="col-md-3 pb-3">
          <div className="col-6 offset-3 col-md-12 offset-md-0">
            {this.generateSVG()}
          </div>
        </div>
        <div className="col-md-9">
          <h1>{this.props.t("assessment.visionary")}</h1>
          <p>{this.props.t("visionary.desc1")}</p>
          <p>{this.props.t("visionary.desc2")}</p>
          <p>{this.props.t("visionary.desc3")}</p>
          <table className="table table-dark table-bordered">
            <thead>
              <tr>
                <th style={{ padding: "0.3rem" }}>{this.props.t("area")}</th>
                <th style={{ padding: "0.3rem" }}>
                  {this.props.t("questions")}
                </th>
                <th style={{ padding: "0.3rem" }}>{this.props.t("notLike")}</th>
                <th style={{ padding: "0.3rem" }}>{this.props.t("notMuch")}</th>
                <th style={{ padding: "0.3rem" }}>
                  {this.props.t("someWhat")}
                </th>
                <th style={{ padding: "0.3rem" }}>{this.props.t("very")}</th>
              </tr>
            </thead>
            <tbody>{elements}</tbody>
          </table>
          <button
            className="btn btn-secondary float-right mt-3"
            onClick={() =>
              this.props.onChange(this.props.id, this.generateSVG())
            }
          >
            {this.props.t("save")}
          </button>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return state.visionaryImplementer;
}

export default connect(mapStateToProps, { setValue })(
  withTranslation()(VisionaryImplementer)
);
