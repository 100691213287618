import React, { Component } from "react";
import { connect } from "react-redux";
import { setOpacity, setValue } from "../store/actions/introvertExtrovert";
import { withTranslation } from "react-i18next";

class IntrovertExtrovert extends Component {
  constructor(props) {
    super(props);
    this.state = {
      IE: "",
    };
    this.handleClick = this.handleClick.bind(this);
    this.generateSVG = this.generateSVG.bind(this);
  }

  static defaultProps = {
    question: {
      ask: "introvert.ask",
      question1: "introvert.question1",
      question2: "introvert.question2",
    },
    answerE: {
      title: "introvert.answerE",
      content: "introvert.answerEContent",
    },
    answerI: {
      title: "introvert.answerI",
      content: "introvert.answerIContent",
    },
  };

  handleClick(d) {
    if (d === "introvert.question1") {
      this.setState({ IE: d });
      this.props.setOpacity("extrovert");
    } else if (d === "introvert.question2") {
      this.setState({ IE: d });
      this.props.setOpacity("introvert");
    } else {
      this.props.onChange(this.props.id, this.generateSVG());
    }
  }

  generateSVG() {
    return (
      <svg
        version="1.1"
        baseProfile="full"
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="xMinYMin meet"
        viewBox="0 0 201 201"
      >
        <g opacity={this.props.introvert}>
          <rect
            x="0.5"
            y="0.5"
            width={this.props.value}
            height="200"
            fill="#ffc107"
            stroke="black"
            strokeWidth="1"
            strokeOpacity={Math.floor(this.props.introvert)}
          />
          <text
            x={this.props.value / 2}
            y="100"
            textAnchor="middle"
            alignmentBaseline="middle"
            fontSize="1.5rem"
            fill="#fff"
          >
            I
          </text>
        </g>
        <g opacity={this.props.extrovert}>
          <rect
            x={this.props.value}
            y="0.5"
            width={200 - this.props.value}
            height="200"
            fill="purple"
            stroke="black"
            strokeWidth="1"
            strokeOpacity={Math.floor(this.props.extrovert)}
          />
          <text
            x={this.props.value / 2 + 100}
            y="100"
            textAnchor="middle"
            alignmentBaseline="middle"
            fontSize="1.5rem"
            fill="#fff"
          >
            E
          </text>
        </g>
      </svg>
    );
  }

  render() {
    let element = "";
    switch (this.state.IE) {
      case "":
        element = (
          <>
            <p>{this.props.t(this.props.question.ask)}</p>
            <p
              className="btn btn-secondary"
              onClick={() => this.handleClick(this.props.question.question1)}
            >
              {this.props.t(this.props.question.question1)}
            </p>
            <br />
            <p
              className="btn btn-secondary"
              onClick={() => this.handleClick(this.props.question.question2)}
            >
              {this.props.t(this.props.question.question2)}
            </p>
          </>
        );
        break;
      case "introvert.question1":
        element = (
          <>
            <div
              className="btn text-white"
              style={{ backgroundColor: "purple" }}
              onClick={this.handleClick}
            >
              <p>{this.props.t(this.props.answerE.title)}</p>
              <p>{this.props.t(this.props.answerE.content)}</p>
            </div>
            <button
              className="btn btn-secondary float-right mt-3"
              onClick={this.handleClick}
            >
              {this.props.t("save")}
            </button>
          </>
        );
        break;
      case "introvert.question2":
        element = (
          <>
            <div
              className="btn btn-warning text-white"
              onClick={this.handleClick}
            >
              <p>{this.props.t(this.props.answerI.title)}</p>
              <p>{this.props.t(this.props.answerI.content)}</p>
            </div>
            <button
              className="btn btn-secondary float-right mt-3"
              onClick={this.handleClick}
            >
              {this.props.t("save")}
            </button>
          </>
        );
        break;
      default:
        break;
    }

    const input =
      this.props.introvert === 1 && this.state.IE !== "" ? (
        <input
          type="range"
          value={this.props.value}
          min="100"
          max="200"
          step="10"
          onChange={(e) => this.props.setValue(+e.target.value)}
        />
      ) : this.props.extrovert === 1 && this.state.IE !== "" ? (
        <input
          type="range"
          value={this.props.value}
          min="0"
          max="100"
          step="10"
          onChange={(e) => this.props.setValue(+e.target.value)}
        />
      ) : null;

    return (
      <div className="row">
        <div className="col-md-3 pb-3">
          {input}
          <div className="col-6 offset-3 col-md-12 offset-md-0">
            {this.generateSVG()}
          </div>
        </div>
        <div className="col-md-9">
          <h2>{this.props.t("assessment.introvert")}</h2>
          {element}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return state.introvertExtrovert;
}

export default connect(mapStateToProps, { setOpacity, setValue })(
  withTranslation()(IntrovertExtrovert)
);
