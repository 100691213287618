import React from "react";
import { connect } from "react-redux";
import { setValue } from "../store/actions/delegation";
import { withTranslation } from "react-i18next";

function Delegation(props) {
  const svg = (
    <svg
      version="1.1"
      baseProfile="full"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMinYMin meet"
      viewBox="0 0 200 200"
    >
      <rect x="0" y="0" width="200" height="200" fill="#145066"></rect>
      <text
        x="100"
        y="100"
        textAnchor="middle"
        alignmentBaseline="middle"
        fontSize="5rem"
        fill="#fff"
      >
        D
      </text>
    </svg>
  );
  return (
    <div>
      <h3>{props.t("assessment.delegation")}</h3>
      <p>{props.t("delegation.desc1")}</p>
      <p>
        <strong>{props.t("delegation.desc2")}</strong>
      </p>
      <table className="table">
        <thead className="thead-dark">
          <tr>
            <th></th>
            <th>{props.t("delegation.question1")}</th>
            <th>{props.t("delegation.question2")}</th>
          </tr>
        </thead>
        <tbody className="thead-dark">
          <tr>
            <th style={{ verticalAlign: "middle", padding: 0 }}>
              <span
                style={{
                  writingMode: "vertical-lr",
                  transform: "rotate(180deg)",
                  whiteSpace: "nowrap",
                }}
              >
                {props.t("delegation.goodAt")}
              </span>
            </th>
            <td style={{ padding: "0.2rem" }}>
              <textarea
                rows="10"
                style={{ width: "100%", resize: "none" }}
                onChange={(e) => props.setValue(e.target.value, 0)}
                value={props.text[0]}
              />
            </td>
            <td style={{ padding: "0.2rem" }}>
              <textarea
                rows="10"
                style={{ width: "100%", resize: "none" }}
                onChange={(e) => props.setValue(e.target.value, 1)}
                value={props.text[1]}
              />
            </td>
          </tr>
          <tr>
            <th style={{ verticalAlign: "middle", padding: 0 }}>
              <span
                style={{
                  writingMode: "vertical-lr",
                  transform: "rotate(180deg)",
                  whiteSpace: "nowrap",
                }}
              >
                {props.t("delegation.notGoodAt")}
              </span>
            </th>
            <td style={{ padding: "0.2rem" }}>
              <textarea
                rows="10"
                style={{ width: "100%", resize: "none" }}
                onChange={(e) => props.setValue(e.target.value, 2)}
                value={props.text[2]}
              />
            </td>
            <td style={{ padding: "0.2rem" }}>
              <textarea
                rows="10"
                style={{ width: "100%", resize: "none" }}
                onChange={(e) => props.setValue(e.target.value, 3)}
                value={props.text[3]}
              />
            </td>
          </tr>
        </tbody>
      </table>
      <button
        className="btn btn-secondary float-right mt-3"
        onClick={() => props.onChange(props.id, svg)}
      >
        {props.t("save")}
      </button>
    </div>
  );
}

function mapStateToProps(state) {
  return state.delegation;
}

export default connect(mapStateToProps, { setValue })(
  withTranslation()(Delegation)
);
