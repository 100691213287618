import { SET_SVG, GET_ASSESSMENT } from "../actionTypes";

const DEFAULT_STATE = {
  svg: [null, null, null, null, null, null, null, null, null, null, null],
};

export default (state = DEFAULT_STATE, action) => {
  let svg = null;
  switch (action.type) {
    case SET_SVG:
      svg = [...state.svg];
      svg[action.idx] = action.svg;
      return { svg };
    case GET_ASSESSMENT:
      svg = [...action.svg];
      return { svg };
    default:
      return state;
  }
};
