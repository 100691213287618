import { SET_OPACITY_IE, SET_VALUE_IE } from "../actionTypes";

const DEFAULT_STATE = {
  extrovert: 0.3,
  introvert: 0.3,
  value: 100,
};

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case SET_OPACITY_IE:
      return { ...DEFAULT_STATE, [action.ie]: 1 };
    case SET_VALUE_IE:
      return { ...state, value: action.val };
    default:
      return state;
  }
};
