import { SET_VALUE_SN } from "../actionTypes";

const DEFAULT_STATE = {
  text: ["", "", "", "", "", "", ""],
};

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case SET_VALUE_SN:
      const text = [...state.text];
      text[action.idx] = action.val;
      return { text };
    default:
      return state;
  }
};
